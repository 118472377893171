/* eslint-disable no-dupe-keys */
//Node modules
import React from 'react'
import {withStyles, Tooltip, Fade, Avatar, IconButton, TextField, ClickAwayListener, Popper, Grid, Button, Modal, FormControl, Divider, Chip, Menu, MenuItem } from '@material-ui/core'
import moment from 'moment';
import { withApollo } from 'react-apollo'
import { PanZoom } from 'react-easy-panzoom'
import _, { filter, isEmpty } from 'lodash'
import RectangleSelection from "react-rectangle-selection"
import {EjectRounded, Remove, Add, TableChartOutlined, Toc, Timer, ArrowBack, Widgets,Flare, MoreVert} from '@material-ui/icons';
import request from 'superagent'
import sanitizeHtml from "sanitize-html"
import { ToastContainer, toast } from 'react-toastify';
import yaml from 'js-yaml';
import {saveAs} from 'file-saver';

//Other modules
import '../../../app/app.css'
import socket from '../../../api/socket'
import config from '../../../config/backend'
import {setStep, setScore, setRoles, returnPermissions, setCurrentPermissions} from '../helper/playerListHelper'
import {getUserId, getUsername, isLeader, ctrl_cmd_mapper, getPlatform, generateString, isObjEmpty} from '../../../utility/function'
import EE from '../../../api/eventemitter'
import RightPanel from './gameRightPanel'
import BlankCardDeck from './blankCardDeck'
import DraggableStack from '../../../components/draggable-stack/draggable-stack.tsx';
import DraggableCard from '../../../components/draggable-card/draggable-card.tsx';
import DraggableDeck  from '../objects/draggableDeck';
import GamePlayerInfo from './gamePlayerInfo'
import CardLoader from '../user_interface/assets/cardLoader'
import {EventManager, CanvasUtil} from '../helper/gameCanvasHelper'
import CanvasState from '../state/canvasState'
import styles from '../user_interface/assets/gameCanvasStyle'
import LeftPanel from './leftPanel/gameLeftPanel'
import Cursor from './cursor/cursor'
import CursorMap from './cursor/cursorMap'
import { JamTimer } from '../../../components/jam/jam-timer/jam-timer.tsx'

toast.configure()

class GameCanvas extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            // Game Data
            general: this.props.data.general,
            objects : this.props.data.objects,
            libraryObjects: new Map(),
            categoryColorMap: new Map(),
            listOfLabels: [],
            listOfCategories: [],
            listOfMultiSelect: [],

            relations : this.props.data.relations,
            latestObject : this.props.data.latestObject,
            latestID: this.props.data.latestID,
            loaded: false,
            event: null,
            message: {
                playerNames: null,
                yamlMessage: null
            },
            status: "active",

            editRulebook: false,

            //Library Data
            cardSelected: null,
            selectedIDs: [],
            canDragLibCard: true,

            // Turn System Data
            playerTurn: CanvasUtil.getPlayerTurn(this.props.data.playerTurn),
            turn: this.props.data.turn,
            phase: this.props.data.phase,

            // Game Participants
            participants: this.props.participants,

            // Game Players
            playerList: null,
            playerStatus: new Map(),
            playerCursorMap: new Map(),

            // Component State
            draggedObject: null,
            vote: null,
            panelOpen: true,
            scale: this.props.data?.canvasData?.scale ?? 1,
            canvasX: this.props.data?.canvasData?.canvasX ?? 1,
            canvasY: this.props.data?.canvasData?.canvasY ?? 1,
            zoomDisabled: true,
            selectionDisabled: true,
            highLightSelection: true,

            // Yaml player config state
            permissionsConfig: null,

            //Yaml game config state
            gameConfig: null,
            currentPhase: null,
            currentTurnStruct: null,

            //Vote
            voteStart: false,
            voteSession: null,

            endOfPhase: false,

            //Zoom
            //zoomAmount: 0.85,
            zoomAnchorEl: null,

            //Mode
            cursorMode: "Cursor",

            //Pin
            listPinIds: [],

            textfieldTarget: null,

            //Image Dummy
            imgData: {
                imgSrc:"",
                imgUploaded: false,
            },

            files: [],

            subDragging: false,

            // Spectator Info Box
            spectatePopUp: false,
            spectateInfo: null, // not null if user is guest without profile

            //Output result box data
            output: false,
            outputCategories: [],
            outputSelectedCategories: [],
            outputCategoryAnchorEl: null,
            outputMenuAnchorEl: null,

            //Internet Connection
            isOnline: navigator.onLine,
        };

        this.os = getPlatform();
        //Debouncing/Throttling the setState changes on mouseWheel changes
        this.handleCanvasChange = _.debounce(this.canvasChange, 100)

        this.canvasRef = null

        // Relation
        this.relation = []

        // Platform
        this.platform = getPlatform()

        this.throttleWheel =  _.throttle((event) =>{

            if(this.os === "MacOS" && event.buttons === 1){
                return;
            }

            if(event.deltaX !== 0 ){
                //MacOS and Trackpad
                //Scroll horizontal handler
                //Scroll down
                if(event.nativeEvent.deltaX > 0){
                    this.canvasRef.setZoom(this.state.canvasX - 100, this.state.canvasY, this.state.scale)
                }
                //Scroll up
                else if(event.nativeEvent.deltaX < 0){
                    this.canvasRef.setZoom(this.state.canvasX + 100, this.state.canvasY, this.state.scale)
                }

                !this.state.zoomDisabled && this.setState({
                    zoomDisabled: true
                })
            }

            if(ctrl_cmd_mapper(event)){
                this.state.zoomDisabled && this.setState({
                    zoomDisabled: false
                })
            }
            else if(event.shiftKey){
                //Scroll horizontal handler
                //Scroll down
                if(event.nativeEvent.deltaY > 0){
                    this.canvasRef.setZoom(this.state.canvasX - 100, this.state.canvasY, this.state.scale)
                }
                //Scroll up
                else if(event.nativeEvent.deltaY < 0){
                        this.canvasRef.setZoom(this.state.canvasX + 100, this.state.canvasY, this.state.scale)
                }

                !this.state.zoomDisabled && this.setState({
                    zoomDisabled: true
                })
            }
            else{
                //Scroll vertical handler
                //Scroll down
                if(event.nativeEvent.deltaY > 0){
                    this.canvasRef.setZoom(this.state.canvasX, this.state.canvasY - 100, this.state.scale)
                }
                //Scroll up
                else if(event.nativeEvent.deltaY < 0){
                    this.canvasRef.setZoom(this.state.canvasX, this.state.canvasY + 100, this.state.scale)
                }

                !this.state.zoomDisabled && this.setState({
                    zoomDisabled: true
                })
            }
        }, 150)

        this.multiSelectOperation = true

        this.textfieldTarget = null

        this.rulebookChangeTimer = null

        this.isSpectate = false  // true if user is not participant of a jam

        // temporary cache for user current mouse position
        this.currentMousePosition = null

        // registers for target card after action
        this.actionEventCard = null

    }

    async componentDidMount(){

        // on canvas , disable overscroll behavior
        document.body.style.overscrollBehaviorX = "none";

        if(CanvasUtil.isEmpty(this.state.phase)){
            const newPhase = {
                turn: 1,
                object: []
            }

            this.setState(prev=>({
                phase: [...prev.phase, newPhase]
            }))
        }

        CanvasState.latestObject = this.state.latestObject

        let promise = new Promise((resolve) =>{
            const finalDeck = this.props.gameData.gameDeck.deck

            let libArr = []
            let categoryColorMap = this.props.gameData?.categoryColorMap ?
                                    new Map(Object.entries(this.props.gameData?.categoryColorMap)) :
                                    new Map()
            let deckCategories = Object.keys(finalDeck)
            let libraryObjects = this.props.gameData?.libraryObjects ?
                                    new Map(Object.entries(this.props.gameData.libraryObjects)) :
                                    new Map()

            if(libraryObjects.size === 0){
                deckCategories.forEach((category) =>{
                    if(category !== "categoryColorMap"){
                        let categoriesArr = this.props.gameData.gameDeck.deck[category]

                        libArr = libArr.concat(categoriesArr)
                        // console.log(libArr)
                    }
                })

                libArr = libArr.concat(this.state.objects)

                for(let i = 0; i < libArr.length; i++){
                    let object = libArr[i]
                    //Converting to string because key saved into database turns into string
                    //therefore saving key into strings beforehand so that everything works as intended
                    let libID = Math.floor(Math.random() * Date.now())
                    libID = libID.toString()
                    object.pin = false
                    object.libID = libID
                    libraryObjects.set(libID, object)
                }
            }

            //Category filtering
            let categoryArr = []
            let listOfCategories = this.props?.gameData?.listOfCategories ? this.props.gameData.listOfCategories : []

            if(listOfCategories.length === 0){
                const categoryList = Object.keys(finalDeck)
                for(let i = 0; i < categoryList.length; i++){
                    if(categoryList[i] !== "categoryColorMap"){
                        let category = finalDeck[categoryList[i]]

                        categoryArr = categoryArr.concat(category)
                    }
                }
                listOfCategories = _.uniqBy(_.map(categoryArr, _.partialRight(_.pick, ['category'])), "category")
                listOfCategories.push({category: "Blank"})
            }

            if(this.props?.gameData?.categoryColorMap){
                categoryColorMap = new Map(Object.entries(this.props.gameData.categoryColorMap))
            }
            else{
                if(finalDeck?.categoryColorMap){
                    categoryColorMap =  new Map(Object.entries(finalDeck.categoryColorMap))
                }
                else{
                    //TODO: Sync with other players to have the same colour for categories
                    let colorArr = ['#1A49A5', '#219653', '#4173D6', '#53CAA6', '#5B3680', '#7956C1', '#A31B1B', '#D08B5B', '#FFAA0D', '#FFC0C0', '#C4C4C4']
                    for(let i = 0; i < listOfCategories.length; i++){
                        let categoryObj = listOfCategories[i]
                        let randColor = colorArr[Math.floor(Math.random() * colorArr.length)]
                        categoryColorMap.set(categoryObj.category, randColor)
                    }
                }

                categoryColorMap.set("Blank", "#FFAA0D")
                categoryColorMap.set("", "#C4C4C4")
                //Save the randomly chosen colour into db so that users won't get a new set of colours

                CanvasUtil.mutateCategoryColor(this.props.gid, Object.fromEntries(categoryColorMap))
            }

            //Label filtering
            let listOfLabels = this.props?.gameData?.listOfLabels ? this.props.gameData.listOfLabels : []

            if(listOfLabels.length === 0){
                const labelList = _.uniqBy(_.map(categoryArr, _.partialRight(_.pick, ['label'])), "label");
                for(let i = 0; i < labelList.length; i++){
                    let labelArr = labelList[i].label

                    if(labelArr){
                        for(let j = 0; j < labelArr.length; j++){
                            let labelStr = labelArr[j]

                            if(!listOfLabels.includes(labelStr) && labelStr){
                                listOfLabels.push({label: labelStr})
                            }
                        }
                    }
                }
                listOfLabels = _.uniqBy(listOfLabels, "label")
            }

            let initialCanvasData = {
                gameConfig: this.props.gameData.gameConfig,
                tppData: this.props.tppData,
            }
            let configData = CanvasUtil.getGameConfig(initialCanvasData, this.state.playerTurn, this.state.participants, this.props.status)

            let playerList = configData.playerList
            let playerCursorMap = new Map()
            let playerStatus = new Map()

            for(let i = 0; i < playerList.length; i++){
                let player = playerList[i]

                playerCursorMap.set(player.uid, {
                    uid: player.uid,
                    username: player.username,
                    usercolor: player.usercolor,
                    x: 1,
                    y: 1
                })

                playerStatus.set(player.uid, "active")
            }

            // check spectator
            let uid = getUserId();
            let temp = playerList.find((n)=>{return n.uid === uid})
            if(!temp){
                this.isSpectate = true;
            }


            this.setState({
                categoryColorMap: categoryColorMap,
                libraryObjects: libraryObjects,
                listOfLabels: listOfLabels,
                listOfCategories: listOfCategories,
                currentTurnStruct: configData.currentTurnStruct,
                currentPhase: configData.currentPhase,
                gameConfig: configData.gameConfig,
                playerList: configData.playerList,
                permissionsConfig: configData.permissionsConfig,
                playerCursorMap: playerCursorMap,
                loaded: true,
            }, () => {
                resolve(configData)
                this.checkUnreceivedEvent();
            })
        })
        await promise

        socket.on('disconnect', reason => {
            console.log("Socket Disconnected",reason)
            this.setState({
                isOnline: false,
            })
        });

        socket.on('connect', data => {
            console.log("Socket Connected")
            this.setState({
                isOnline: true,
            })
        })

        socket.on("Game Vote Close", ()=>{
            this.voteClose();
        })

        socket.on("Canvas Flip Object", data => {

            let objects = [...this.state.objects]
            let index = objects.findIndex((n)=> {return n.objId === data.id})
            if(objects[index]!=null){
                objects[index].flip = data.flip
                this.setState({
                    objects: objects
                })
            }
        })

        socket.on("Game Card Position Sync", data => {
            let objects = [...this.state.objects]
            let index = objects.findIndex((n)=>{return n.objId === data.id})
            if(objects[index]!=null){

                objects[index].pos.x = data.x
                objects[index].pos.y = data.y

                this.setState({
                    objects: objects
                })
            }
        })

        socket.on("Sync Objects", data => {

            this.setState({
                objects: data.objects
            });
        })

        socket.on("Game Card Content Complete Sync", data => {

            let objects = [...this.state.objects]
            let index = objects.findIndex((n)=>{return n.objId === data.id})
            objects[index].content = data.content
            objects[index].status = "completed"

            this.setState({
                objects: objects
            })
        })

        socket.on("Game Card Content Incomplete Sync", data => {

            let objects = [...this.state.objects]
            let index = objects.findIndex((n)=>{return n.objId === data.id})
            objects[index].status = "incomplete"

            this.setState({
                objects: objects
            })
        })

        //Called from gameCanvas when adding a new object
        socket.on("Game Add Object", data => {

            const last = this.state.phase.length - 1;
            const temp = [...this.state.phase]
            temp[last].object.push(data.obj)

            // restruct object here , add type

            this.setState({
                objects: [...this.state.objects, data.obj],
                latestObject: data.obj,
                phase: temp,
                latestID: data.latestID,
            })
        })

        //Called from gameCanvas when fulfilling an event from the YAML when adding object
        socket.on("Game Add Object w/ Playerlist", data => {

            const last = this.state.phase.length - 1;
            const temp = [...this.state.phase]
            temp[last].object.push(data.obj)

            const permission = returnPermissions(getUserId(), data.playerList)

            this.setState({
                event: data.event,
                message: data.message,
                currentTurnStruct: data.currentTurnStruct,
                currentPhase: data.currentPhase,
                playerTurn: data.newPlayerTurn,
                playerList: data.playerList,
                permissionsConfig: permission,
                objects: [...this.state.objects, data.obj],
                latestObject: data.obj,
                phase: temp,
                latestID: data.latestID,
                endOfPhase: data.endOfPhase
            }, () =>{
                CanvasState.latestObject = this.state.latestObject
                // if(this.state.event == null){
                //     setTimeout(() =>{
                //         this.messageTimeout()
                //     }, 5000)
                // }
            })
        })

        socket.on("Game Add Multi Object", (data) =>{
            const {latestID, objects} = data

            this.setState({
                objects: objects,
                latestID: latestID
            });
        })

        //Called from draggableStack.js
        socket.on("Sync Stack", data =>{
            const {objs , objId} = data

            this.setState(state =>{
                const objects = state.objects.map((object)=>{
                    if(object.objId === objId){
                        object.objs = objs
                        return object
                    }
                    else{
                        return object
                    }
                })
                return {objects}
            },()=>{
                const {objects} = this.state
                CanvasUtil.mutateGameObjects(objects, this.props.gid)
            })
        })

        //Called from draggableStack.js
        socket.on("Sync Stack Flip", data =>{
            const {flipStack , objId} = data

            this.setState(state =>{
                const objects = state.objects.map((object)=>{
                    if(object.objId === objId){
                        object.flipStack = flipStack
                        return object
                    }
                    else{
                        return object
                    }
                })
                return {objects}
            },()=>{
                const {objects} = this.state
                CanvasUtil.mutateGameObjects(objects, this.props.gid)
            });
        })

        //Called from gamePlayerHand
        EE.on("Game Object Dragging", data => {
            this.setState({
                listOfMultiSelect: [],
                selectedIDs: []
            },()=>{
                // !IMPORTANT! : DRAGGED DOESN'T NEED TO CARE TYPE
                let dragged = {
                    type: data.card.type,
                    data: data.card,
                    from: data?.from
                }

                this.draggedObject = dragged
            })
        })

        //Called from blankCard
        EE.on("Canvas Blank Card Dragging", () => {
            // IMPORTANT! : DRAGGED DOENST NEED TO CARE TYPE
            let dragged = {
                type: "blank",
                category: "Blank",
                data: {
                    value: "",
                    status: "incomplete",
                    flip: false,
                    blank: 1,
                    category: "Blank",
                    content: ""
                }
            }

            this.draggedObject = dragged
        })

        //Called from event to pass to target card owner
        EE.on("Game Target Change Player", ()=>{

            let newTurnStruct = null
            let updatedPlayerList = null
            let permissionsConfig = null
            let turnStruct = newTurnStruct ?? this.state.currentTurnStruct

            // console.log(this.state.playerList)
            // console.log(this.actionEventCard)
            let playerTurn = this.state.playerList.findIndex((p)=>{
                return p.uid === this.actionEventCard.origin.id
            })
            // console.log(playerTurn)

            updatedPlayerList = setRoles(turnStruct, playerTurn, this.state.participants)
            updatedPlayerList = setStep(turnStruct, updatedPlayerList)
            permissionsConfig = returnPermissions(getUserId(), updatedPlayerList)

            this.setState({
                currentTurnStruct: turnStruct,
                playerList: updatedPlayerList,
                permissionsConfig: permissionsConfig,
                playerTurn: playerTurn,
                message: {
                    playerNames: [this.state.playerList[playerTurn].username],
                    yamlMessage: "[0] is now the Storyteller. <br/> Everyone else can steal the turn!"
                }
            },()=>{
                socket.emit("Game Target Change Player", {
                    updatedCurrentTurnStruct: this.state.currentTurnStruct,
                    updatedPlayerList: this.state.playerList,
                    updatedPlayerTurn: this.state.playerTurn,
                    updatedMessage: this.state.message
                });
                const {general,objects,latestObject, playerTurn, phase, turn, latestID} = this.state
                CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
                CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)
            })

        })

        //Called from gamePlayerInfo when "Pass turn"
        EE.on("Game Normal Change Player", ()=> {
            let newTurnStruct = null
            let playerTurn = 0
            let updatedPlayerList = null
            let permissionsConfig = null
            let looped = false
            if(this.state.gameConfig.deck.drawConfig.onPass){
                EE.emit("Game OnPass Draw Action");
            }

            let turnStruct = newTurnStruct ?? this.state.currentTurnStruct

            if(this.state.playerTurn >= this.state.playerList.length-1){
                looped = true
                updatedPlayerList = setRoles(turnStruct, 0, this.state.participants)
            }
            else{
                playerTurn = this.state.playerTurn + 1
                updatedPlayerList = setRoles(turnStruct, this.state.playerTurn + 1, this.state.participants)
            }

            updatedPlayerList = setStep(turnStruct, updatedPlayerList)
            permissionsConfig = returnPermissions(getUserId(), updatedPlayerList)

            this.setState({
                currentTurnStruct: turnStruct,
                playerList: updatedPlayerList,
                permissionsConfig: permissionsConfig,
                playerTurn: playerTurn,
                message: {
                    playerNames: [this.state.playerList[playerTurn].username],
                    yamlMessage: "[0] is now the Storyteller. <br/> Everyone else can steal the turn!"
                }
            },()=>{
                socket.emit("Game Normal Change Player", {
                    updatedCurrentTurnStruct: this.state.currentTurnStruct,
                    updatedPlayerList: this.state.playerList,
                    updatedPlayerTurn: this.state.playerTurn,
                    updatedMessage: this.state.message
                });
                const {general,objects,latestObject, playerTurn, phase, turn, latestID} = this.state
                CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
                CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)
            })
        })

        //Called from gameCanvas when "Pass turn"
        socket.on("Game Normal Change Player", (data)=> {
            const {updatedPlayerList , updatedPlayerTurn, updatedCurrentTurnStruct, updatedMessage} = data

            let permissionsConfig = returnPermissions(getUserId(), updatedPlayerList)
            this.setState({
                currentTurnStruct: updatedCurrentTurnStruct,
                playerList: updatedPlayerList,
                playerTurn: updatedPlayerTurn,
                message: updatedMessage,
                permissionsConfig: permissionsConfig
            },()=>{
                let plist = this.state.playerList
                let currentTurnStruct = this.state.currentTurnStruct
                let curID = getUserId();
                for(let index = 0; index < plist.length; index++){
                    let player = plist[index]
                    if((plist[index].uid === curID ) && plist[index].currentStep){
                        let n = CanvasState.gameConfig
                                [currentTurnStruct][player["turnStatus"]]
                                [player["role"]]
                                [plist[index]["currentStep"]].events
                        // console.log(n)

                        if(n && n.hasOwnProperty(n.startingEvent)){
                
                            let se = n.startingEvent
                            if(!isObjEmpty(n[se])){
                                // console.log(n[se])
                                setTimeout(()=>{EE.emit("Step Event",n)},1000)
                            }
                        }
                    }
                }
            });
        })

        socket.on("Game Target Change Player", (data)=> {
            const {updatedPlayerList , updatedPlayerTurn, updatedCurrentTurnStruct, updatedMessage} = data

            let permissionsConfig = returnPermissions(getUserId(), updatedPlayerList)
            this.setState({
                currentTurnStruct: updatedCurrentTurnStruct,
                playerList: updatedPlayerList,
                playerTurn: updatedPlayerTurn,
                message: updatedMessage,
                permissionsConfig: permissionsConfig
            },()=>{
                let plist = this.state.playerList
                let currentTurnStruct = this.state.currentTurnStruct
                let curID = getUserId();
                for(let index = 0; index < plist.length; index++){
                    let player = plist[index]
                    if((plist[index].uid === curID ) && plist[index].currentStep){
                        let n = CanvasState.gameConfig
                                [currentTurnStruct][player["turnStatus"]]
                                [player["role"]]
                                [plist[index]["currentStep"]].events
                        // console.log(n)

                        if(n && n.hasOwnProperty(n.startingEvent)){
                
                            let se = n.startingEvent
                            if(!isObjEmpty(n[se])){
                                // console.log(n[se])
                                setTimeout(()=>{EE.emit("Step Event",n)},1000)
                            }
                        }
                    }
                }
            });
        })

        EE.on("Game Change Player YAML", data => {
            const {playerIndex} = data;

            const newPlayerList = setRoles(this.state.currentTurnStruct, playerIndex, this.state.playerList)
            const playerListWithStep = setStep(this.state.currentTurnStruct, newPlayerList)
            const newPermissionsConfig = returnPermissions(getUserId(), playerListWithStep)

            this.setState({
                playerList: playerListWithStep,
                permissionsConfig: newPermissionsConfig,
                playerTurn: playerIndex,
            },() =>{
                socket.emit("Game Change Player YAML", {
                    newPlayerList: this.state.playerList,
                    newPlayerTurn: playerIndex,
                })
                const {general,objects,latestObject, playerTurn, phase, turn, latestID} = this.state
                CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
                CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)
            });
        })

        socket.on("Game Change Player YAML", (data)=> {
            const {newPlayerList, newPlayerTurn} = data
            const newPermissionsConfig = returnPermissions(getUserId(), newPlayerList)

            this.setState({
                playerList: newPlayerList,
                permissionsConfig: newPermissionsConfig,
                playerTurn: newPlayerTurn
            });
        })

        socket.on("Game Update PlayerList", data =>{
            const {currentTurnStruct, currentPhase, playerList, playerTurn, endOfPhase}  = data

            const permission = returnPermissions(getUserId(),playerList)

            this.setState({
                currentTurnStruct: currentTurnStruct,
                currentPhase: currentPhase,
                playerList: playerList,
                permissionsConfig: permission,
                playerTurn: playerTurn,
                endOfPhase: endOfPhase,
            },()=>{
                let plist = this.state.playerList
                let currentTurnStruct = this.state.currentTurnStruct
                let curID = getUserId();
                for(let index = 0; index < plist.length; index++){
                    let player = plist[index]
                    if((plist[index].uid === curID ) && plist[index].currentStep){
                        let n = CanvasState.gameConfig
                                [currentTurnStruct][player["turnStatus"]]
                                [player["role"]]
                                [plist[index]["currentStep"]].events
                        // console.log(n)
                        if(n && n.hasOwnProperty(n.startingEvent)){
                
                            let se = n.startingEvent
                            if(!isObjEmpty(n[se])){
                                // console.log(n[se])
                                setTimeout(()=>{EE.emit("Step Event",n)},1000)
                            }
                        }
                    }
                }
            });
        })

        socket.on("Pass Turn Msg", data =>{
            const {message} = data
            this.setState({
                message: message
            })
        })

        socket.on("Pass Turn YAML", data =>{
            const {message, event, endOfPhase, newPlayerTurn, playerList, currentPhase, currentTurnStruct} = data
            const permissions = returnPermissions(getUserId(), playerList)

            this.setState({
                message: message,
                event: event,
                endOfPhase: endOfPhase,
                playerTurn: newPlayerTurn,
                playerList: playerList,
                currentPhase: currentPhase,
                currentTurnStruct: currentTurnStruct,
                permissionsConfig: permissions
            });
        })

        EE.on("Call for Vote", (data) =>{
            const {permissions} = data

            this.setState({
                permissionsConfig: permissions
            }, ()=>{
                socket.emit("Call for Vote", {permissions: permissions})
            });
        })

        socket.on("Call for Vote", (data)=>{
            const {permissions} = data

            this.setState({
                permissionsConfig: permissions
            });
        })

        EE.on("Vote Starts", (data) => {
            const {voteStart} = data
            this.setState({voteStart: voteStart})
        })

        //Called from gameVoteSession
        EE.on("Vote Ends", (data) => {
            const {voteStart} = data

            const permissionsConfig = setCurrentPermissions(this.state.currentTurnStruct, this.state.playerList, getUserId())
            this.setState({
                voteStart: voteStart,
                permissionsConfig: permissionsConfig
            })
        })

        socket.on("Vote Ends", (data) => {
            const {voteStart} = data

            const permissionsConfig = setCurrentPermissions(this.state.currentTurnStruct, this.state.playerList, getUserId())
            this.setState({
                voteStart: voteStart,
                permissionsConfig: permissionsConfig
            })
        })

        EE.on("Delete Card", (data) => {
            const {objID} = data

            let deleteLibObj = false

            this.setState(state =>{
                const libraryObjects = state.libraryObjects
                const listOfMultiSelect = state.listOfMultiSelect
                const objects = state.objects.filter((obj, index) => {
                    if(obj.objId !== objID){
                        return true
                    }
                    else{
                        deleteLibObj = libraryObjects.delete(obj?.libID)
                        listOfMultiSelect.splice(index, 1)
                        return false
                    }
                })

                return{
                    objects,
                    libraryObjects,
                    listOfMultiSelect
                }
            }, () =>{
                const {objects, libraryObjects} = this.state

                socket.emit("Sync Objects", {objects: objects})

                CanvasUtil.mutateGameObjects(objects, this.props.gid)
                deleteLibObj && socket.emit("Sync Library", {libraryObjects: Object.fromEntries(libraryObjects)})
            });
        })

        EE.on("Clear Card", (data) =>{
            const {cardType} = data

            this.setState(state =>{
                let objects = state.objects
                if(cardType === "All"){
                    objects = []
                }
                else{
                    objects = objects.filter((object) =>{
                        return object.category !== cardType
                    })
                }

                return{
                    objects
                }
            }, () =>{
                socket.emit("Sync Objects", {objects: this.state.objects})
            });
        })

        socket.on("Change Category Color", (data) =>{
            const {categoryColorMap} = data

            this.setState({
                categoryColorMap: new Map(Object.entries(categoryColorMap))
            })
        })

        socket.on("Sync Library", (data) =>{
            const {libraryObjects} = data

            // console.log("Sync Library: ", libraryObjects)

            this.setState({
                libraryObjects: new Map(Object.entries(libraryObjects))
            })
        })

        // socket.on("Sync Player Cursor", (data) =>{
        //     const {uID, playerCursor} = data

        //     this.setState(state =>{
        //         const playerCursorMap = state.playerCursorMap

        //         playerCursorMap.set(uID, playerCursor)

        //         return{
        //             playerCursorMap
        //         }
        //     })
        // })

        EE.on("Event Script Shift To", (data) =>{
            const {shiftTo, shiftAction} = data
            let uid = getUserId()
            let keyActionObj = null
            let canvasState = {
                gameConfig: this.state.gameConfig,
                currentTurnStruct: this.state.currentTurnStruct,
                playerList: this.state.playerList,
                loaded: this.state.loaded,
                playerTurn: this.state.playerTurn,
                playerID: uid
            }

            keyActionObj = CanvasUtil.keyActionPerformed(shiftAction, shiftTo, canvasState)

            this.setState(prev =>({
                currentTurnStruct: keyActionObj?.newCurrentTurnStruct ?? prev.currentTurnStruct,
                currentPhase: keyActionObj?.newCurrentPhase ?? prev.currentPhase,
                playerList: keyActionObj?.updatedPlayerList ?? prev.playerList,
                permissionsConfig: keyActionObj?.updatedPermission ?? prev.permissionsConfig,
                playerTurn: keyActionObj?.newPlayerTurn ?? prev.playerTurn,
                endOfPhase: keyActionObj?.endOfPhase,
            }), () =>{
                const {general,objects,latestObject, playerTurn, phase, turn, latestID} = this.state
                CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
                CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)

                socket.emit("Game Update PlayerList", {
                    currentTurnStruct: this.state.currentTurnStruct,
                    currentPhase: this.state.currentPhase,
                    playerList: this.state.playerList,
                    playerTurn: this.state.playerTurn,
                    endOfPhase: this.state.endOfPhase,
                })

                return
            });
        })

        // document.getElementById('canvas2').addEventListener('pointermove', event =>{
        //     if(event.buttons !== 4){
        //         this.trackPlayerCursor(event, getUserId())
        //     }
        // }, true)

        /**
         * Sync Player Score is calling from gameCanvas
         */
        socket.on("Sync Player Score", (data) =>{
            const {uID, score} = data
            let updatedPlayer = setScore(this.state.playerList, uID, score)

            this.setState({
                playerList: updatedPlayer
            })
        })

        socket.on("Game Rulebook Change", (data) =>{
            const {rulebook} = data

            this.setState(state =>{
                let general = state.general
                general.rulebook = rulebook

                return{
                    general
                }
            })
        })

        // //Keyboard shortcut for zoom in and zoom out
        // document.addEventListener('keydown', (e) => {
        //     const targetTagName = e?.target?.tagName.toLowerCase();
        //     const targetId = e?.target?.id;
        //     if (targetTagName && ((targetTagName === 'input') || targetId === 'contentEditable')) {
        //         return;
        //     }

        //     switch (e.key) {
        //         case '+':
        //         case '=':
        //         {
        //             this.handleZoomIn();
        //             break;
        //         }
        //         case '-': {
        //             this.handleZoomOut();
        //             break;
        //         }
        //         default: break;
        //     }
        // }, true);

        // Event Emitter for spectator popUp
        EE.on("Spectator Popup",()=>{
            this.setState({
                spectatePopUp: "chat",
            })
        })

        // temp output
        EE.on("Open Output",()=>{
            this.setState({
                output: true,
            })
        })

        EE.on("Update Object Pairing", (data)=>{
            let objects = [...this.state.objects]
            let index = objects.findIndex((n)=> {return n.objId === data.id})
            if(objects[index]!=null){
                objects[index].paired = data.paired
                this.setState({
                    objects: objects
                })
            }
        })

        EE.on("Notification", (data)=>{
            let {title,msg} = data
            this.snackbar(title,msg); // call snackbar popUps
        })

        EE.on("Step Event", (data)=>{
            let userIds = [getUserId(), this.state.playerList[this.state.playerTurn]["uid"]]
            let drawOps = {
                playerList: this.state.playerList,
                playerTurn: this.state.playerTurn,
            }

            EventManager.eventStruct = data
            //EventManager.stepEvent = true
            EventManager.initStepEvent(userIds, this.state.playerList, drawOps, this.state.objects[this.state.objects.length - 1], this.props.gid)
            socket.emit("Event Manager Data", {userIds: userIds, playerList: this.state.playerList, drawOps: drawOps})
        })

        EE.on("Card Snapping",(data)=>{
            this.actionEventCard = this.state.objects.find((o)=>{return o.objId === data.origin})

            // console.log(this.actionEventCard)
            // should mutate here
          })

        socket.on('Game Notification Action', (data)=>{
            let {notification,notifyTarget} = data
            let uid = getUserId()
            //console.log("notification", data)
            if(notifyTarget === "All"){
                this.snackbar("Jambuilder Message",notification)
                //this.updateNotificationLog(uid,notification)
            }else{
                if(notifyTarget === uid){
                    this.snackbar("Jambuilder Message", notification)
                   // this.updateNotificationLog(uid,notification)
                }
            }
        })  

        socket.on("Card Pairing",(data)=>{
            let objects = [...this.state.objects]
            let index = objects.findIndex((n)=> {return n.objId === data.id})
            let index2 = objects.findIndex((n)=> {return n.objId === data.origin})

            if(objects[index]!=null){
                objects[index].paired = data.paired

            }

            if(objects[index2]!=null){
                objects[index2].paired = data.originPaired
            }

            this.setState({
                objects: objects
            },()=>{
                // need to mutate both object to record paired
                // ***mutation here cause every client mutate 
                //CanvasUtil.mutateGameObjects(objects, this.props.gid)
            })
        })


        socket.on("Card Unpairing",(data)=>{
            let {unpairInfo} = data
            let objects = [...this.state.objects]
            let originIndex = objects.findIndex((n)=>{return n.objId === data.unpairOrigin})

            for(let i = 0; i<unpairInfo.length; i++){
                let index = objects.findIndex((n)=>{return n.objId === unpairInfo[i].to})
                if(objects[index]!=null){
                    objects[index].paired[unpairInfo[i].dir] = null
                }
            }

            if(objects[originIndex]!=null){
                objects[originIndex].paired = {
                    left: null,
                    right: null,
                    up: null,
                    down: null,
                }
            }

            this.setState({
                objects: objects
            },()=>{
                // CanvasUtil.mutateGameObjects(objects, this.props.gid)
            })

        })
    }

    render(){
        const {classes,gid} = this.props
        const { canvasX, canvasY, scale } = this.state;
        const others = this.state.objects.filter((n)=>{return n.type !== "stack"})
        const stack = this.state.objects.filter((n)=>{return n.type === "stack"})
        const cards = others.filter((n)=>{return n.type !== "deck"})
        const deck = others.filter((n)=>{return n.type === "deck"})
        const canvasData = {
            x: canvasX,
            y: canvasY,
            scale,
        }
        let spectatorID = this.getSpectateID()
        const outputParents = this.state.objects.filter((o)=>{
            if(this.state.outputCategories.includes(o.category)){
                return true
            }
        })

        let outputObjects = this.getOutputObject();

        // console.log(this.state.multiSelect)
        // console.log(this.props.build)
        // avoid rerender canvas - trackPLayerCursor - canvasData change on Panzoom
        // console.log(this.state.listOfCategories)
        // console.log(this.state.outputCategories)
        // console.log(this.state.gameConfig,this.state.playerList)
        // console.log(this.state.permissionsConfig)
        // console.log(EventManager.stepEvent)
        // console.log(this.state)
        // console.log(this.state.playerList)

        return (this.state.loaded && this.state.isOnline)  ?
            <div
                id="canvasWrapper"
                className={classes.canvasWrapper}
            >
                {this.state.voteStart &&
                    <div style={{width:"100%", height:"100%", background:"rgba(0,0,0,0.4)", display:"block", position:"absolute", zIndex:"3"}}/>
                }
                {/*------START OF CANVAS TOP PANEL-------*/}
                <Grid
                    container justify="center"
                    alignItems="center"
                    className={`${classes.roomTitleWrapper} ${classes.panelClose}`}
                    direction="row"
                >
                    <Grid item xs={4}>
                        {this.props.build &&
                            <Button
                                onClick={()=>{
                                    let temp = "undefined"
                                    if(this.props.builderID){
                                        temp = this.props.builderID
                                    }else{
                                        temp = this.props.gameData.builderID
                                    }
                                    window.location.href=`${window.location.origin}/jambuilder/${temp}`
                                }}
                                style={{background:"#FA841B", color:"white", marginLeft:"20px"}} startIcon={<ArrowBack style={{fontSize:"14px"}}/>}
                            >
                                <span style={{fontSize:"10px", fontFamily:"Arial"}}>BUILD MODE</span>
                            </Button>
                            }

                        {this.props.setup &&
                        <Button
                            onClick={()=>{
                                let arr = {
                                    objects: this.state.objects,
                                    latestID: this.state.latestID,
                                    libraryObjects: this.state.libraryObjects,
                                    categoryColorMap: this.state.categoryColorMap,
                                    listOfLabels: this.state.listOfLabels,
                                    listOfCategories: this.state.listOfCategories
                                }
                                this.props.setupChanges(arr)
                                this.props.refetch()
                            }}
                            style={{background:"#FA841B", color:"white", marginLeft:"20px"}} startIcon={<ArrowBack style={{fontSize:"14px"}}/>}
                        >
                            <span style={{fontSize:"10px", fontFamily:"Arial"}}>Back to Waiting Room</span>
                        </Button>
                        }
                        <div style={{float:"left", marginLeft:"10px"}}>
                            <Tooltip title={<p style={{margin:"0"}}>Zoom Out <span style={{color:"#D1D1D1", marginLeft:"20px"}}>-</span></p>}>
                                <Button onClick={()=>{this.handleZoomOut()}}className={classes.zoomBtn} style={{borderRight:"none", borderRadius:"5px 0 0 5px"}}>
                                    <Remove className={classes.zoomIcon}/>
                                </Button>
                            </Tooltip>
                            <p className={classes.zoomText}>
                                {this.getZoomPercentage()}%
                            </p>
                            <Tooltip title={<p style={{margin:"0"}}>Zoom In <span style={{color:"#D1D1D1", marginLeft:"20px"}}>+</span></p>}>
                                <Button onClick={()=>{this.handleZoomIn()}} className={classes.zoomBtn} style={{borderLeft:"none", borderRadius:"0 5px 5px 0", }}>
                                    <Add className={classes.zoomIcon}/>
                                </Button>
                            </Tooltip>
                        </div>
                        <Button style={{background:"#A9A9A9", color:"white", marginTop:"3px", marginLeft:"2em"}}
                                        disabled>
                                <span style={{fontSize:"10px", fontFamily:"Arial"}}>
                                    {
                                    // CanvasUtil.getAutomationTitle(this.props.automation)
                                    `${this.state.currentPhase} - ${this.state.currentTurnStruct}`
                                    }
                                </span>
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <span className={classes.roomTitleName}>{this.props.roomName}</span>
                                {/* MODE AND GRAPH INFO */}
                                {this.props.setup ?
                                    <Button style={{background:"transparent", color:"white", marginTop:"-3px", marginLeft:"10px", border:"1px solid white"}}
                                    startIcon={<Widgets style={{fontSize:"14px"}}/>}
                                    disabled>
                                        <span style={{fontSize:"10px", fontFamily:"Arial"}}>
                                            Setup
                                        </span>
                                    </Button>
                                    :
                                    <>
                                        <Button
                                            style={{background:"transparent", color:"white", marginTop:"-3px", marginLeft:"10px", border:"1px solid white"}}
                                            startIcon={<Flare style={{fontSize:"14px"}}/>}
                                            disabled
                                        >
                                            <span style={{fontSize:"10px", fontFamily:"Arial"}}>
                                                Jamming
                                            </span>
                                        </Button>
                                    </>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.hostTitle}>
                            Hosted by <strong>{this.props.createdBy.name.first}</strong>
                        </div>
                        {/* ----- START OF RULEBOOK ------ */}
                        <ClickAwayListener onClickAway={this.closeRulebook}>
                            <div style={{float: 'right',}}>
                                <Popper
                                    open={this.state.rulebookEl ? true : false}
                                    anchorEl={this.state.rulebookEl}
                                    placement="bottom-end"
                                    transition
                                >
                                    {({ TransitionProps }) => (
                                    <Fade {...TransitionProps}>
                                        <div className={classes.popper}>
                                            <EjectRounded className={classes.popperTriangle} />

                                            <p style={{color:"#4F4F4F", fontWeight:"bold", fontSize:"14px", margin:"0"}}>
                                                Rulebook
                                                <Button
                                                    style={{
                                                        fontFamily:"Arial",
                                                        fontSize:"10px",
                                                        color: this.state.editRulebook ? "#fff" : "#4173D6",
                                                        backgroundColor: this.state.editRulebook && '#FA841B',
                                                        fontWeight:"bold",
                                                        margin:"0 0 0.3rem 4.5rem",
                                                        padding: "0.1rem",
                                                        outline:"none"
                                                    }}
                                                    onClick={() => {
                                                        this.setState(prevState =>({
                                                            editRulebook: !prevState.editRulebook
                                                        }))
                                                    }}
                                                >
                                                    {this.state.editRulebook ? "Done" : "Edit"}
                                                </Button>
                                                <br/>
                                                <span style={{color:"#828282", fontWeight:"normal", fontSize:"12px"}}>
                                                    <TableChartOutlined style={{marginTop:"-3px", fontSize:"1rem"}}/> {this.props.data.general.name}
                                                </span>
                                            </p>

                                            <br/>

                                            <TextField
                                                disabled={!this.state.editRulebook}
                                                name="rulebook"
                                                style={{width:"100%", background:"none", border:"none"}}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    style:{
                                                        color: "#4F4F4F",
                                                        fontSize:"12px",
                                                        fontFamily:"Arial",
                                                        backgroundColor: this.state.editRulebook && "#FAF7EA"
                                                    }
                                                }}
                                                multiline={true}
                                                onChange={(e) =>{
                                                    this.rulebookChangeTimer && clearTimeout(this.rulebookChangeTimer)
                                                    let general = this.state.general
                                                    general.rulebook = e.target.value
                                                    this.setState({
                                                        general: general
                                                    }, () =>{
                                                        this.rulebookChangeTimer = setTimeout(() =>{
                                                            !this.props.setup && socket.emit("Game Rulebook Change", {rulebook: this.state.general.rulebook})
                                                            CanvasUtil.mutateRulebook(gid, this.state.general.rulebook)
                                                        }, 500)
                                                    })
                                                }}
                                                value={this.state.general.rulebook}
                                            />
                                        </div>
                                    </Fade>
                                    )}
                                </Popper>
                                <IconButton className={classes.iconTopPanel} onClick={(e) => this.openRulebook(e)} style={{borderRadius:"0px", color: this.state.rulebookEl ? "#4F4F4F" : "#FFF"}}>
                                    <Toc/>
                                </IconButton>
                            </div>
                        </ClickAwayListener>
                        {/* ----- END OF RULEBOOK ------ */}
                        {/* ----- START OF TIMER ------ */}
                        <JamTimer
                            classes={classes}
                            initialTimerOn={this.props.data?.timer?.timerOn}
                            initialTimervalue={this.props.data?.timer?.timer}
                            snackbar={this.snackbar}
                        />
                        {/* ----- END OF TIMER ------ */}
                    </Grid>
                </Grid>
                {/*------END OF CANVAS TOP PANEL-------*/}
                <LeftPanel
                    listOfLabels={this.state.listOfLabels}
                    listOfCategories={this.state.listOfCategories}
                    objects={this.state.libraryObjects}
                    categoryColorMap={this.state.categoryColorMap}
                    addNewLibraryCard={this.addNewLibraryCard}
                    moveViewToCard={this.moveViewToCard}
                    deleteLibrary = {this.deleteLibrary}
                    duplicateSingleLibraryCard={this.duplicateSingleLibraryCard}
                    selectCardsInLibrary = {this.selectCardsInLibrary}
                    selectedIDs={this.state.selectedIDs}
                    canDragLibCard={this.state.canDragLibCard}
                    updMode={this.updMode}
                    cursorMode={this.state.cursorMode}
                    isLeader ={isLeader(getUserId(),this.props.createdBy._id)}
                    startSelectCardsLibrary = {this.startSelectCardsLibrary}
                    clearLibrarySelection = {this.clearLibrarySelection}
                    imgData = {this.state.imgData}
                    clearMultiSelect = {this.clearMultiSelect}
                    handleZoomIn = {this.handleZoomIn}
                    handleZoomOut = {this.handleZoomOut}
                />
                {/* Canvas Right Panel */}
                <RightPanel
                    listOfLabels={this.state.listOfLabels}
                    listOfCategories={this.state.listOfCategories}
                    categoryColorMap={this.state.categoryColorMap}
                    panelOpen = {this.rightPanel}
                    gid={this.props.gid}
                    data={this.props.data}
                    participants={this.props.participants}
                    automation={this.props.automation}
                    inhand={this.props.inhand}
                    deckConfig={this.state.gameConfig.deck}
                    usedDeck={this.props.usedDeck}
                    finalDeck={this.props.gameData.gameDeck.deck}
                    createdBy={this.props.createdBy}
                    changeCard={this.changeCard}
                    changeColor={this.changeColor}
                    cardSelected={this.state.libraryObjects.get(this.state.selectedIDs[0])}
                    selectedIDs = {this.state.selectedIDs}
                    gameTranscript={this.props.gameTranscript}
                    isLeader={isLeader(getUserId(),this.props.createdBy._id)}
                    clearMultiSelect = {this.clearMultiSelect}
                    listPinIds={this.state.listPinIds}
                    libraryObjects={this.state.libraryObjects}
                    pinRightPanel = {this.pinRightPanel}
                    imgData = {this.state.imgData}
                    onImgUpload = {this.onImgUpload}
                    onImgPaste = {this.onImgPaste}
                    removeImg = {this.removeImg}
                    onDrop = {this.onDrop}
                    isSpectate = {this.isSpectate}
                    spectateInfo = {this.state.spectateInfo}

                />
                <BlankCardDeck
                    clearMultiSelect={this.clearMultiSelect}
                    automation = {this.props.automation}
                    permissionsConfig = {this.state.permissionsConfig}
                    isTurn = {CanvasUtil.isTurn(this.state.playerList, this.state.playerTurn)}
                    deckConfig = {this.state.deckConfig}
                    panelOpen = {this.state.panelOpen}
                />
                <div
                    onMouseDown={(e) =>{
                        e.persist()

                        if(e.button === 0 && !e.shiftKey && (e.target.id === 'canvas' || e.target.id === "canvas2")){
                            if(this.state.textfieldTarget){
                                this.setState({
                                    textfieldTarget: null
                                })
                            }
                            else{
                                e.preventDefault()
                            }
                            this.clearMultiSelect()
                            this.setSubDragging(false)
                            EE.emit("Canvas Focus")
                        }
                    }}
                    onDragOver={(e)=>{e.preventDefault()}}
                    onDrop={(e)=>{
                        if(this.isSpectate && this.state.spectateInfo == null && !getUserId()){
                            this.currentMousePosition = {
                                x:e.clientX,
                                y:e.clientY,
                            }
                            EE.emit("Canvas Defocus")
                            this.setState({
                                spectatePopUp: "card",
                            })
                        }else{
                            this.submit(e.clientX, e.clientY)
                        }
                    }}
                    style={
                        {position:"relative",
                        top:"3rem",
                        height:"calc(100% - 3.1rem)",
                        //border:"1px solid blue",
                        width: this.state.panelOpen ? "calc(100% - 310px)" : "calc(100% - 43px)"}}
                >
                    <RectangleSelection
                        onSelect={(e, coords) => {
                            if(e.button === 0 && (e.target.id === 'canvas' || e.target.id === "canvas2") && this.state.subDragging === false){
                                e.preventDefault()
                                let selectionData = CanvasUtil.checkSelectionOverlap(coords.origin, coords.target)

                                this.setState({
                                    listOfMultiSelect: selectionData.listOfOverlapObjects,
                                    selectedIDs: selectionData.listOfOverlapLibObj
                                })
                            }
                        }}
                        style={{
                            backgroundColor: "rgba(191,205,233,0.4)",
                            border: "1px solid #4173D6",
                            marginTop:"-6.5rem"
                        }}
                        disabled={this.state.cursorMode === "Hand" || this.state.textfieldTarget ? true : false || this.state.subDragging}
                    >
                        <PanZoom
                            id ='canvas2'
                            noStateUpdate
                            onPointerMove={(event)=>{
                                event.persist()
                                if(event.buttons !== 4 && this.isSpectate == false){
                                    //this.trackPlayerCursor(event, getUserId())
                                     EE.emit("Cursor Move", {event: event, uID: getUserId()})
                                }
                            }}
                            realPinch={true}
                            zoomSpeed={2}
                            disableScrollZoom={this.state.zoomDisabled}
                            preventPan={(e) =>{
                                if(this.state.cursorMode === "Hand"){
                                    return false
                                }
                                if(e.button === 0){
                                    return true
                                }

                                if(this.state.textfieldTarget){
                                    return true
                                }

                                else{ return false}
                            }}
                            onWheel={event =>{
                                event.persist()
                                event.stopPropagation()
                                this.throttleWheel(event);

                            }}
                            disableDoubleClickZoom={true}
                            disableKeyInteraction={true}
                            minZoom = {0.21}
                            maxZoom = {4.25}
                            style={this.state.cursorMode === "Cursor" ?
                            {
                                height: "calc(100% + 3rem)",
                                width: this.state.panelOpen ? "calc(100% - 310px)" : "calc(100% - 43px)",
                                cursor:`url(${require("../../../element/Cursor/Cursor.png")}), auto`,
                                outline:"none",
                                "&:focus":{
                                    outline:"none"
                                }
                            }
                            :
                            {
                                height: "calc(100% + 3rem)",
                                width: this.state.panelOpen ? "calc(100% - 310px)" : "calc(100% - 43px)",
                                cursor:`url(${require("../../../element/Cursor/Pan.png")}), auto`,
                                "&:focus":{
                                    outline:"none"
                                }
                            }}
                            onKeyDown = {(e) =>{}}
                            onStateChange={(canvasValue) =>{
                                const {x, y , scale, angle} = canvasValue
                                this.setState({
                                    scale: scale,
                                    canvasX: x,
                                    canvasY: y,
                                })
                            }}
                            ref={ref =>this.canvasRef = ref}
                        >
                                <CursorMap initialCursorMap={this.state.playerCursorMap} canvasY={this.state.canvasY} canvasX={this.state.canvasX} scale={this.state.scale}/>
                                <div
                                    id="canvas"
                                    //className={this.state.cursorMode === "Cursor" ? `${classes.containerCursor}`: classes.containerHand}
                                    className={classes.containerCursor}
                                >
                                    {cards.map((obj) => (
                                        <DraggableCard
                                            key={`${obj + obj.objId}`}
                                            gid={this.props.gid}
                                            categoryColor={this.state.categoryColorMap.get(obj.category)}
                                            obj={obj}
                                            scale={this.state.scale}
                                            addStackObject = {this.addStackObject}
                                            checkAction = {this.checkAction}
                                            cardConfig = {this.state.permissionsConfig.cardConfig}
                                            canvasCoordinates = {{canvasX: this.state.canvasX, canvasY: this.state.canvasY}}
                                            changeCard = {this.changeCard}
                                            startMultiSelect = {this.startMultiSelect}
                                            updateMultiPosition = {this.updateMultiPosition}
                                            syncMultiPosition = {this.syncMultiPosition}
                                            newMultiSelect = {this.newMultiSelect}
                                            featureMultiSelect = {this.featureMultiSelect}
                                            clearMultiSelect = {this.clearMultiSelect}
                                            receiveTargetTextfield = {this.receiveTargetTextfield}
                                            multiSelect = {this.state.listOfMultiSelect.includes(obj.objId)}
                                            cursorMode = {this.state.cursorMode}
                                            onlySelect = {this.state.listOfMultiSelect.length === 1 ? true : false}
                                            listPinIds = {this.state.listPinIds}
                                            selectedIDs = {this.state.selectedIDs}
                                            libraryObjects = {this.state.libraryObjects}
                                            imgData = {this.state.imgData}
                                            onImgUpload = {this.onImgUpload}
                                            onImgPaste = {this.onImgPaste}
                                            removeImg = {this.removeImg}
                                            onDrop = {this.onDrop}
                                            moveBack = {this.moveBack}
                                            spectatorID = {spectatorID}
                                            isSpectate = {this.isSpectate}
                                            playerList = {this.state.playerList}
                                            changeAEC = {this.changeAEC}
                                        />
                                    ))}

                                    {stack.map((obj) => (
                                        <DraggableStack
                                            key={`${obj + obj.objId}`}
                                            gid={this.props.gid}
                                            obj={obj}
                                            scale={this.state.scale}
                                            latestID = {this.state.latestID}
                                            addStackObject = {this.addStackObject}
                                            addObject = {this.addObject}
                                            addMultiObject = {this.addMultiObject}
                                            cardConfig = {this.state.permissionsConfig.cardConfig}
                                            categoryColorMap={this.state.categoryColorMap}
                                            canvasCoordinates = {{canvasX: this.state.canvasX, canvasY: this.state.canvasY}}
                                            cursorMode = {this.state.cursorMode}
                                            imgData = {this.state.imgData}
                                            updateStackObject = {this.updateStackObject}
                                            updateMultiPosition = {this.updateMultiPosition}
                                            receiveTargetTextfield = {this.receiveTargetTextfield}
                                            canvasData = {canvasData}
                                            formDeck = {this.formDeck}
                                            setSubDragging  = {this.setSubDragging}
                                            clearMultiSelect = {this.clearMultiSelect}
                                            selectedCards={this.state.listOfMultiSelect}
                                            setSelectedCard={this.setSelectedCard}
                                            selectCardsInLibrary={this.selectCardsInLibrary}
                                        />
                                    ))}
                                    {deck.map((obj) => (
                                            <DraggableDeck
                                                key={`${obj + obj.objId}`}
                                                gid={this.props.gid}
                                                obj={obj}
                                                scale={this.state.scale}
                                                latestID = {this.state.latestID}
                                                addStackObject = {this.addStackObject}
                                                addObject = {this.addObject}
                                                addMultiObject = {this.addMultiObject}
                                                cardConfig = {this.state.permissionsConfig.cardConfig}
                                                categoryColorMap={this.state.categoryColorMap}
                                                canvasCoordinates = {{canvasX: this.state.canvasX, canvasY: this.state.canvasY}}
                                                cursorMode = {this.state.cursorMode}
                                                imgData = {this.state.imgData}
                                                updateStackObject = {this.updateStackObject}
                                                receiveTargetTextfield = {this.receiveTargetTextfield}
                                                canvasData = {canvasData}
                                                formStack = {this.formStack}
                                                setSubDragging  = {this.setSubDragging}
                                                clearMultiSelect = {this.clearMultiSelect}
                                            />
                                    ))}


                                </div>
                            </PanZoom>
                        </RectangleSelection>
                    </div>
                <GamePlayerInfo
                    style={{zIndex:"101"}}
                    message = {this.state.message}
                    playerList = {this.state.playerList}
                    passing = {this.state.permissionsConfig?.componentConfig?.passing}
                    passAction = {this.state.permissionsConfig?.actions?.["Pass Turn"]}
                    playerTurn={this.state.playerTurn}
                    checkAction = {this.checkAction}
                    setScorePlayerList = {this.setScorePlayerList}
                    // playerStatus = {this.state.playerStatus}
                />
                {/* SNACKBAR */}
                <ToastContainer
                    position="bottom-left"
                    autoClose={false}
                    // hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Modal open={this.state.spectatePopUp ? true : false} onClose={this.closeSpectatePopUp} aria-labelledby="Spectator Info Box">
                    <div className={classes.specModal}>
                        <p className={classes.specModalHeader}>
                            Join as Guest
                        </p>
                        <Divider/>
                        <div className={classes.specModalContent}>
                            <FormControl style={{width: "100%", fontSize:"0.875rem", color:"#4F4F4F"}}>
                                <b>Your Nickname</b>
                                <input
                                    className={classes.specField}
                                    placeholder="Nickname"
                                    name="name"
                                    id="spectate-name"
                                    maxLength={10}
                                />
                                <b>Email Address</b>
                                <input
                                    className={classes.specField}
                                    placeholder="Email"
                                    name="email"
                                    id="spectate-email"
                                    maxLength={50}
                                />
                                <Button
                                    onClick={()=>{
                                        let email = document.getElementById('spectate-email').value
                                        let name = document.getElementById('spectate-name').value
                                        if(email === "" || name === ""){
                                            alert("Please Fill All Information.")
                                        }
                                        else{
                                            let spectator = {
                                                spectator_email: email,
                                                spectator_name: name,
                                                spectator_id: `Guest-${generateString(10)}`,
                                            }

                                            if(this.state.spectatePopUp === "chat"){
                                                this.setState({
                                                    spectateInfo: spectator,
                                                    spectatePopUp: false,
                                                })
                                            }else{
                                                this.setState({
                                                    spectateInfo: spectator,
                                                    spectatePopUp: false,
                                                },()=>{
                                                    this.submit(this.currentMousePosition.x, this.currentMousePosition.y)
                                                })
                                            }

                                        };
                                    }}
                                    className={classes.specPopUpBtn}
                                >
                                    Enter Jam
                                </Button>
                            </FormControl >
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.output} onClose={this.closeOutput} aria-labelledby="Output Box">
                    <div className={classes.outputModal}>
                        <div style={{display:"flex", marginBottom:"1em", paddingTop:"1em"}}>
                            <h1 style={{flex: "3"}} className={classes.outputModalGeader}>Output</h1>
                            <IconButton className={classes.outputModalMenu} onClick={(e) => this.setState({outputMenuAnchorEl: e.currentTarget})}>
                                <MoreVert style={{ color: '#c4c4c4' }} />
                            </IconButton>
                        </div>
                        <div style={{display:"flex", flexWrap:"wrap",}}>
                            {this.state.outputCategories.map((category, index) => (
                                    <Chip
                                        variant="default"
                                        label={category}
                                        onDelete={()=>{this.handleDeleteOutputCategory(index)}}
                                        style={{
                                            background: this.state.categoryColorMap.get(category),
                                            borderRadius:"5px", border:"none", color:"white",marginRight:"5px"}}
                                        size="small"
                                    />
                                ))
                            }
                            <Chip
                                // disabled={this.state.outputCategories.length>0}
                                disabled
                                variant="default"
                                label="Category"
                                icon={<Add style={{color:"white"}}/>}
                                style={{
                                    //background: categoryColorMap.get(option.category),
                                    borderRadius:"5px", border:"none", color:"white", marginRight:"5px",backgroundColor:"#C4C4C4",display:"inline",paddingTop:"1px"}}
                                size="small"
                                onClick={(event)=>{this.setState({outputCategoryAnchorEl:event.currentTarget})}}
                            />
                        </div>
                        <div style={{marginTop:"20px", fontSize:"16px"}}>
                            {
                                outputObjects.map((o,index)=>
                                    <ul key={index}>
                                        <RenderOutput node={o}/>
                                    </ul>
                                )
                                // outputParents.map((op)=>{
                                //     return(
                                //         <p style={{fontSize:"20px"}}>{op.content}</p>
                                //     )
                                // })
                                // outputObjects[0].map((objs)=>{
                                //     for(let i = 0; i<objs.length; i++){
                                //         Object.keys(objs[i]).map(()=>{z})
                                //     }
                                // })
                            }
                        </div>
                        <Menu
                            id="output-category-menu"
                            anchorEl={this.state.outputCategoryAnchorEl}
                            PaperProps={{style: { fontFamily:"Montserrat, sans-serif", color:"#4F4F4F", border:"0.5px solid #c4c4c4"}}}
                            open={Boolean(this.state.outputCategoryAnchorEl)}
                            onClose={()=>{this.setState({contentAnchorEl:null})}}
                        >
                            {this.state.listOfCategories.map((item,index)=>{
                                if(!this.state.outputCategories.includes(item.category)){  //compare all categories on canvas with selected "outputCategories"
                                    return(
                                        <MenuItem 
                                            onClick={()=>{
                                                let n = this.state.outputCategories; 
                                                n.push(item.category); 
                                                this.setState({
                                                    outputCategories:n, 
                                                    outputCategoryAnchorEl: null
                                                });  
                                            }} 
                                            style={{fontSize:"12px"}} 
                                            component="label"
                                            key={index}
                                        >
                                            <p>{item.category}</p>
                                        </MenuItem>
                                    )
                                }
                            })}
                        </Menu>
                        <Menu
                            id="output-menu"
                            anchorEl={this.state.outputMenuAnchorEl}
                            PaperProps={{style: { fontFamily:"Montserrat, sans-serif", color:"#4F4F4F", border:"0.5px solid #c4c4c4"}}}
                            open={Boolean(this.state.outputMenuAnchorEl)}
                            onClose={()=>{this.setState({outputMenuAnchorEl:null})}}
                        >
                            <MenuItem onClick={()=>{ this.downloadOutput(outputObjects); this.setState({outputMenuAnchorEl:null});  }} style={{fontSize:"16px"}} component="label">
                                <p>Download YAML</p>
                            </MenuItem>
                        </Menu>
                    </div>
                </Modal>
            </div>
        :
        (<CardLoader/>)
    }


    trackPlayerCursor = (event, uID) =>{
        this.setState(state =>{
            const playerCursorMap = state.playerCursorMap
            const playerCursorData = playerCursorMap.get(uID)

            playerCursorData.x = ((-this.state.canvasX + event.pageX) / this.state.scale)
            playerCursorData.y = ((-this.state.canvasY + event.pageY - 112) / this.state.scale)

            playerCursorMap.set(uID, playerCursorData)

            return{
                playerCursorMap
            }
        }, () =>{
            const playerCursorMap = this.state.playerCursorMap

            socket.emit("Sync Player Cursor", {uID: uID, playerCursor: playerCursorMap.get(uID)})
        })
    }

    displayPlayersCursor = () =>{
        const playerCursorMap = this.state.playerCursorMap
        const playerCursorArr = []

        for(const [uID, cursorData] of playerCursorMap.entries()){
            if(getUserId() !== uID){
                playerCursorArr.push(
                    <Cursor
                        key={uID}
                        cursorData={cursorData}
                    />
                )
            }
        }

        return playerCursorArr
    }

    openRulebook = (e) =>{
        this.setState({
            rulebookEl: e.currentTarget
        });
    }

    closeRulebook = () => {
      if (this.state.rulebookEl !== null) {
        this.setState({ rulebookEl: null });
      }
    }

    /**
     * snackbar is to show the toastify message
     * @param {*} title is the snackbar's title
     * @param {*} message is the snackbar's message
     */
     snackbar = (title, message) =>{
        const {classes} = this.props
        let uid = getUserId()
        this.updateNotificationLog(uid,message)

        toast(({ closeToast }) =>
                <Grid container direction="row" justify="flex-start" alignItems="center"
                style={{borderLeft:"5px solid #f2c94c", margin:"-10px", padding:"1em"}}>
                    <Grid item xs={2}>
                        <Avatar
                            style={{float:"left", marginRight:"10px", width:"40px", height:"40px", border:"2px solid white"}}
                            src={require('../../../element/abu.png')}>
                        </Avatar>
                    </Grid>
                    <Grid item xs={10}>
                        <p style={{/*color:"#53CAA6"*/ color: "#f2c94c", fontWeight:"bold", margin:"0", marginLeft:"5px", fontSize:"12px"}}>
                            {title}
                        </p>
                        <p style={{margin:"0", overflowWrap: "break-word", wordWrap: "break-word", marginLeft:"5px", fontSize:"12px", whiteSpace:"break-spaces"}}>
                            {message}
                        </p>
                    </Grid>
                </Grid>,
            {
                className:classes.toast,
                position:"bottom-left",
                autoClose: 10000, // control autoclose
                hideProgressBar:true,
                progressClassName: classes.toastBar
            })
    }

    closeZoomMenu = () =>{
        this.setState({
            zoomAnchorEl: null
        })
    }

    getZoomPercentage = () =>{
        if(this.canvasRef){
            let amount = this.canvasRef.state.scale
            amount = Math.round((amount + Number.EPSILON) * 100) / 100
            let percentage = amount / 1 * 100
            return Math.round(percentage)
        }
        else{
            return 100
        }
    }

    handleZoomIn = () =>{
        this.canvasRef.zoomIn()
    }

    handleZoomOut = () =>{
        this.canvasRef.zoomOut()

        // this.forceUpdate()
    }

    canvasChange = () =>{
        this.setState({
            scale : this.scale,
            canvasX: this.canvasX,
            canvasY: this.canvasY
        }, () =>{
            this.mutateCanvasData()
        });
    }

    /**
     * Receives e.target from textfields in draggable cards to defocus the textfield
     * whenever the user clicks on the canvas when the textfield is being focused
     * @param {object} target
     */
    receiveTargetTextfield = (target) =>{
        this.setState({
            textfieldTarget: target
        })
        // this.textfieldTarget = target
    }

    /**
     * Start selection via holding shift and click onto a card
     * @param {string} objID card's objectID
     * @param {string} libID card's responding libID
     */
    startMultiSelect = (objID, libID) =>{
        this.setState(state => {
            const listOfMultiSelect = state.listOfMultiSelect;
            const selectedIDs = state.selectedIDs

            const multiSelectIndex = listOfMultiSelect.indexOf(objID);
            const selectedIDIndex = selectedIDs.indexOf(libID)

            //If the object is not selected then select it, else unselect it
            multiSelectIndex > -1 ?
                listOfMultiSelect.splice(multiSelectIndex, 1) :
                listOfMultiSelect.push(objID);

            selectedIDIndex > -1 ?
                selectedIDs.splice(selectedIDIndex, 1) :
                selectedIDs.push(libID)

            return{
                listOfMultiSelect,
                selectedIDs
            }
        });
    }

    /**
     * Updates one or more cards position based on user selection
     * @param {object} ui user's mouse dragging event
     */
    updateMultiPosition = (ui) =>{
        const {listOfMultiSelect} = this.state

        for(let i = 0; i < listOfMultiSelect.length; i++) {
            let objID = listOfMultiSelect[i]
            CanvasState.objRefMethods.get(objID).updatePosition(ui)
        }
    }

    /* 
        After Card onStop, here perform database update and sync,
        ** need a way to update only once during multi-select
    */
    syncMultiPosition = (ui) => { 
        const {listOfMultiSelect} = this.state
        // console.log(listOfMultiSelect)

        for(let i = 0; i < listOfMultiSelect.length; i++) {
            let objID = listOfMultiSelect[i]
            CanvasState.objRefMethods.get(objID).syncPosition(ui)
        }
    }

    /**
     * Removes the multiselect on other cards if the user drags
     * around a card that is not under selection
     * @param {string} objID card's objID on canvas
     * @param {string} libID card's libID in library
     */
    newMultiSelect = (objID, libID) => {
        // const {listOfMultiSelect} = this.state
        this.setState({
            listOfMultiSelect: [objID],
            selectedIDs: [libID]
        })

    }
    /**
     * Clears the multi select selection whenever user creates a new selection box
     */
    clearMultiSelect = () =>{
        if(this.state.selectedIDs.length > 0){
            socket.emit("Game Object Parse Links", {selectedIDs : this.state.selectedIDs})
            this.setState({
                listOfMultiSelect: [],
                selectedIDs: []
            })
        }
    }

    /**
     * Handler for card features during multi selection cards
     * @param {string} option selected in the card dropdown
     * @param {string} targetedCardId is the card id for the menu which the option was clicked on
     */
    featureMultiSelect = (option, targetedCardId = null) =>{
        //Flag to prevent all the cards from calling the same feature at once
        if(this.multiSelectOperation){
            this.multiSelectOperation = false
            console.log(targetedCardId)
            switch(option){
                case "Add IHL":
                    const {objects, listOfMultiSelect} = this.state
                    let cardArr = []

                    for(let i = 0; i < objects.length; i++){
                        let obj = objects[i]

                        if(listOfMultiSelect.includes(obj.objId)){

                            obj.id = obj.objId

                            cardArr.push(obj)
                        }
                    }

                    EE.emit("Multiple Privatise Card", {cardArr: cardArr})

                    this.deleteMultiSelect()

                    break
                case "Delete":
                    this.deleteMultiSelect()
                    break
                case "Flip":
                    this.setState(state =>{
                        const listOfMultiSelect = state.listOfMultiSelect
                        const objects = state.objects
                        for(let i = 0; i < objects.length; i++){
                            let obj = objects[i]
                            if(listOfMultiSelect.includes(obj.objId)){
                               obj.flip = !obj.flip
                            }
                        }

                        return{
                            objects
                        }
                    }, () =>{
                        const {objects, listOfMultiSelect} = this.state
                        socket.emit("Multiple Card Action" , {option: option, listOfMultiSelect: listOfMultiSelect})
                        socket.emit("Sync Objects", {objects: objects})
                        CanvasUtil.mutateGameObjects(objects, this.props.gid)
                    })
                    break
                case "Pin":
                    this.setState(state =>{
                        let listPinIds = state.listPinIds
                        const selectedIDs = state.selectedIDs

                        for(let i=0; i<selectedIDs.length; i++){
                            const id = selectedIDs[i]
                            if(!listPinIds.includes(id)){
                                listPinIds.push(id)
                            }
                            else{
                                const j = listPinIds.indexOf(id)
                                listPinIds.splice(j, 1)
                            }
                        }
                        return{
                            listPinIds
                        }
                    })
                    break
                case "Stack":
                    /**
                     * sometimes anchor el null error will appear when you remove multiselected stack object
                        move card forward & backward doesn't work anymore
                        sometimes when you create a multiselected stack, the other cards on the canvas will not be able to move
                     */
                    this.multiStackSelect(targetedCardId)
                    break
                default:
                    break
            }

            this.multiSelectOperation = true
        }
    }

    /**
     * Handler for deletion during multi select
     */
    deleteMultiSelect = () =>{
        let deleteLibObj = null
        this.setState(state =>{
            const listOfMultiSelect = state.listOfMultiSelect
            const libraryObjects = state.libraryObjects
            const objects = state.objects.filter((obj) => {
                if(listOfMultiSelect.includes(obj.objId)){
                    deleteLibObj = libraryObjects.delete(obj?.libID)
                    return false
                }
                else{
                    return true
                }
            })

            return{
                objects,
                libraryObjects,
                listOfMultiSelect: []
            }
        }, () =>{
            const {objects, libraryObjects} = this.state

            socket.emit("Sync Objects", {objects: objects})

            CanvasUtil.mutateGameObjects(objects, this.props.gid)
            //Todo: Change this to follow an array of libObj rather than one libobj only
            deleteLibObj && socket.emit("Sync Library", {libraryObjects: Object.fromEntries(libraryObjects)})
        })
    }

    startSelectCardsLibrary = (object) =>{
        
        this.setState({
            listOfMultiSelect: object?.objId ? [object.objId] : [],
            selectedIDs: [object.libID]
        })
    }

    /**
     * Handles the selection of the cards whether in cards or in canvas
     * The function is here to retain the selection data whenever users are
     * selecting the cards on canvas then only opening the library panel
     * @param {object} object card obj data selected from library
     */
    selectCardsInLibrary = (object) =>{
        this.setState(state =>{
            const selectedIDs = state.selectedIDs
            const listOfMultiSelect = state.listOfMultiSelect
            const libraryObjects = state.libraryObjects
            let canDragLibCard = true

            if(listOfMultiSelect.includes(object.objId)){
                listOfMultiSelect.splice(listOfMultiSelect.indexOf(object.objId), 1)
            }
            else{
                if(object?.objId){
                    listOfMultiSelect.push(object.objId)
                }
            } 

            if(selectedIDs.includes(object.libID)){
                selectedIDs.splice(selectedIDs.indexOf(object.libID), 1)
            }
            else{
                selectedIDs.push(object.libID)
            }

            for(let i = 0; i < selectedIDs.length; i++){
                let selectedID = selectedIDs[i]
                let libObj = libraryObjects.get(selectedID)

                if(libObj?.objId){
                    canDragLibCard = false
                }
                break
            }


            return{
                selectedIDs,
                listOfMultiSelect,
                canDragLibCard: canDragLibCard
            }
        })
    }

    clearLibrarySelection = () =>{
        this.setState({
            selectedIDs: [],
            listOfMultiSelect: []
        })
    }

    updMode = (mode) => {
        this.setState({
            cursorMode: mode
        })
    }

    mutateCanvasData(){
        const gid = this.props.gid
        const {scale, canvasX, canvasY} = this.state
        const canvasData = {
            scale: scale,
            canvasX: canvasX,
            canvasY: canvasY
        }
        CanvasUtil.mutateCanvasData(gid, canvasData)
    }

    checkAction = (action, category = null) =>{
        // console.log(action)
        if(this.props?.status !== "started" || this.isSpectate){
            return false
        }

        let uid = getUserId()
        let keyActionObj = null
        let canvasState = {
            gameConfig: this.state.gameConfig,
            currentTurnStruct: this.state.currentTurnStruct,
            playerList: this.state.playerList,
            loaded: this.state.loaded,
            playerTurn: this.state.playerTurn,
            playerID: uid
        }

        let player = this.state.playerList.find(player => player.uid === uid)
        let playerAction = player["permissions"]?.["actions"]?.[action]

        category = category ? category : "Any"

        //So the bool is evaluated like this
        //If there is a structure under the action based on category,
        //if not then check Any, then check normal structure to accommodate Pass Turn
        let shiftTo = playerAction?.[category]?.["shiftTo"] ?? playerAction?.["Any"]?.["shiftTo"] ?? playerAction?.["shiftTo"]
        let shiftAction = playerAction?.[category]?.["shiftAction"] ?? playerAction?.["Any"]?.["shiftAction"] ?? playerAction?.["shiftAction"]

        let event = playerAction?.[category]?.["event"] ?? playerAction?.["Any"]?.["event"] ?? playerAction?.["event"]

        if(shiftAction){
            keyActionObj = CanvasUtil.keyActionPerformed(shiftAction, shiftTo, canvasState)

            this.setState(prev =>({
                currentTurnStruct: keyActionObj?.newCurrentTurnStruct ?? prev.currentTurnStruct,
                currentPhase: keyActionObj?.newCurrentPhase ?? prev.currentPhase,
                playerList: keyActionObj?.updatedPlayerList ?? prev.playerList,
                permissionsConfig: keyActionObj?.updatedPermission ?? prev.permissionsConfig,
                playerTurn: keyActionObj?.newPlayerTurn ?? prev.playerTurn,
                endOfPhase: keyActionObj?.endOfPhase,
            }), () =>{
                const {general,objects,latestObject, playerTurn, phase, turn, latestID} = this.state
                CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
                CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)

                socket.emit("Game Update PlayerList", {
                    currentTurnStruct: this.state.currentTurnStruct,
                    currentPhase: this.state.currentPhase,
                    playerList: this.state.playerList,
                    playerTurn: this.state.playerTurn,
                    endOfPhase: this.state.endOfPhase,
                })

                return
            });
        }
        //If Event script
        else if(event){
            //Get the start of event, do check as you go
            let eventName = event
            let userIds = [getUserId(), this.state.playerList[this.state.playerTurn]["uid"]]
            let drawOps = {
                playerList: this.state.playerList,
                playerTurn: this.state.playerTurn,
            }

            EventManager.eventStruct = this.state.gameConfig["events"][eventName]
            EventManager.initEvent(
                userIds, 
                this.state.playerList, drawOps, 
                this.state.objects[this.state.objects.length - 1], 
                this.props.gid, this.actionEventCard, 
                {jamtitle: this.props.roomName, jamlink: this.props.gid}
            )

            socket.emit("Event Manager Data", {userIds: userIds, playerList: this.state.playerList, drawOps: drawOps})
            return
        }
        else if(action === "Pass"){
            EE.emit("Game Normal Change Player")
            return
        }
    }

    /**
     * multiStackObject is called from draggableCard.js to create stack from clicking on the ellipsis menu
     * @param {string} targetedCardId is the card libID for the menu which the option was clicked on
    */
    multiStackSelect = (targetedCardId) =>{
        const {selectedIDs, libraryObjects, listOfMultiSelect, objects} = this.state
        const uid = getUserId()
        let listObjs = []

        const targetedCard = objects.find((card)=>{return (card.objId === targetedCardId)})

        for(let i=0; i <listOfMultiSelect.length; i++){
            let objID = listOfMultiSelect[i]

            //The text of objects in state.objects does not get updated whenever
            //you type into the card...
            //so to cheat our way through we directly get the state of the object using ref

            let curObj =  CanvasState.objRefMethods.get(objID).state
            if(curObj.category === "Stack"){
                for(let i = 0; i<curObj.objs.length;i++){
                    listObjs.push(curObj.objs[i])
                }
            }else{
                listObjs.push(
                    CanvasState.objRefMethods.get(objID).state
                )
            }

        }

        // console.log(listObjs)

        let obj = {
            objId: `${uid}-${this.state.latestID}`,
            origin: {
                username: getUsername(),
                id: uid,
            },
            flip:false,
            edited:false,
            pin:false,
            vote: false,
            flipStack: false,
            anchorEl: null,
            objs: [...listObjs],
            type: "stack",
            category: "Stack",
            label: ["Stack"],
            time: moment.utc(),
            pos: {
                x: targetedCard.pos.x,
                y: targetedCard.pos.y
            },
        }

        this.setState(state =>{
            let objects = state.objects.filter((card)=>{
                return (!listOfMultiSelect.includes(card.objId))
            })

            objects.push(obj)
            const latestID = (parseInt(state.latestID) + 1).toString()

            return{
                objects,
                latestID,
                listOfMultiSelect: [],
                selectedIDs: []
            }
        }, ()=>{
            //Emit to gameCanvas
            socket.emit('Game Add Multi Object', {objects: this.state.objects, latestID: this.state.latestID})
            const {general,objects,latestObject, playerTurn, phase, turn, latestID} = this.state
            CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
            CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)
        })
    }

    addStackObject = (dragCardState, touchCardState) => {

        if(dragCardState.type !== "stack" && touchCardState.type !== "stack"){
            //If neither are stack cards
            const uid = getUserId()

            let obj = {
                objId: `${uid}-${this.state.latestID}`,
                origin: {
                    username: getUsername(),
                    id: uid,
                },
                flip:false,
                edited:false,
                pin:false,
                vote: false,
                flipStack: false,
                anchorEl: null,
                objs: [touchCardState, dragCardState],
                type: "stack",
                category: "Stack",
                label: ["Stack"],
                time: moment.utc(),
                pos: {
                    x: touchCardState.x,
                    y: touchCardState.y
                },
            }
            this.setState(state =>{
                let objects = state.objects.filter((card)=>{
                    return (card.objId !== touchCardState.id && card.objId !== dragCardState.id)
                })
                objects.push(obj)
                const latestID = (parseInt(state.latestID) + 1).toString()

                return{
                    objects,
                    latestID
                }
            }, ()=>{
                //Emit to gameCanvas
                socket.emit('Game Add Multi Object', {objects: this.state.objects, latestID: this.state.latestID})
                const {general,objects,latestObject, playerTurn, phase, turn, latestID} = this.state
                CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
                CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)
            })
        }
        else if(touchCardState.type === "stack"){
            //If the touched card is a stack
            this.setState(state =>{
                let objects = []
                for(let i = 0; i < state.objects.length; i++){
                    let obj = state.objects[i];
                    if(obj.objId === touchCardState.id){
                        //Is the dragged card a stack or blank?
                        obj.objs = dragCardState.type !== "stack" ? [...obj.objs, dragCardState] : obj.objs.concat(...dragCardState.objs)
                        CanvasState.objRefMethods.get(touchCardState.id).addObj(obj.objs)
                    }

                    if(obj.objId !== dragCardState.id){
                        objects.push(obj)
                    }
                }

                return{
                    objects
                }
            }, ()=>{
                //Emit to gameCanvas
                socket.emit('Sync Objects', {objects: this.state.objects})
                const {objects} = this.state
                CanvasUtil.mutateGameObjects(objects, this.props.gid)
            })
        }
        else if(dragCardState.type === "stack" && touchCardState.type !== "stack"){
            this.setState(state =>{
                let objects = []
                for(let i = 0; i < state.objects.length; i++){
                    let obj = state.objects[i];

                    if(obj.objId === dragCardState.id){
                        //Is the dragged card a stack or blank?
                        obj.objs = [touchCardState, ...dragCardState.objs]
                        CanvasState.objRefMethods.get(dragCardState.id).addObj(obj.objs)
                    }

                    if(obj.objId !== touchCardState.id){
                        objects.push(obj)
                    }
                }

                return{
                    objects
                }
            },() =>{
                //Emit to gameCanvas
                socket.emit('Sync Objects', {objects: this.state.objects})
                const {objects} = this.state
                CanvasUtil.mutateGameObjects(objects, this.props.gid)
            });
        }

        this.checkAction("Stack Card")
    }

    updateStackObject = (objId, updateData, type) =>{
        this.setState(state =>{
            const objects = state.objects

            for(let i = 0; i < objects.length; i++){
                let object = objects[i]
                if(type === 'Objects'){
                    const {objs} = updateData

                    if(object.objId === objId){
                        object.objs = objs
                        break
                    }
                }
            }
            return {objects}
        }, () =>{
            const {objects} = this.state

            CanvasUtil.mutateGameObjects(objects, this.props.gid)
        });
    }

    formDeck = (objId) => {
        this.setState(state =>{
            const objects = state.objects

            for(let i = 0; i < objects.length; i++){
                if(objects[i].objId === objId){
                    objects[i].type = "deck"
                }
            }
            return {objects}
        }, () =>{
            const {objects} = this.state
            socket.emit("Sync Objects", {objects: objects})
            CanvasUtil.mutateGameObjects(objects, this.props.gid)

        });
    }

    formStack = (objId) => {
        this.setState((state) => {
            const { objects } = state;

            const next = objects.map((obj) => {
                if (obj.objId !== objId) {
                    return obj;
                }

                return {
                    ...obj,
                    type: 'stack',
                };
            });

            return { objects: next };
        }, () => {
            const { objects } = this.state;
            socket.emit('Sync Objects', {objects: objects});
            CanvasUtil.mutateGameObjects(objects, this.props.gid);
        });
    }

    addObject = (obj) =>{
        let uid = getUserId()
        let player = this.state.playerList.find(player => player.uid === uid)
        let addCard;
        if(this.isSpectate){
            addCard = this.state.permissionsConfig["actions"]?.["Add Card"]
        }else{
            addCard = player["permissions"]?.["actions"]?.["Add Card"]
        }

        let keyActionObj = null
        let canvasState = {
            gameConfig: this.state.gameConfig,
            currentTurnStruct: this.state.currentTurnStruct,
            playerList: this.state.playerList,
            loaded: this.state.loaded,
            playerTurn: this.state.playerTurn,
            playerID: uid
        }

        //Event Scripts & Key Actions
        if((addCard && (addCard?.[obj.category] || addCard?.["Any"])) && this.props.status === "started"){
            //If Key Action
            if(addCard[obj.category]?.["shiftAction"] || addCard["Any"]?.["shiftAction"]){
                let shiftTo = addCard?.[obj.category]?.["shiftTo"] ??  addCard?.["Any"]?.["shiftTo"]
                let shiftAction = addCard?.[obj.category]?.["shiftAction"] ?? addCard?.["Any"]?.["shiftAction"]

                keyActionObj = CanvasUtil.keyActionPerformed(shiftAction, shiftTo, canvasState)
            }
            else if(addCard[obj.category]?.["event"] || addCard["Any"]?.["event"]){

                //Get the start of event, do check as you go
                let eventName = addCard?.[obj.category]?.["event"] ?? addCard?.["Any"]?.["event"]
                let userIds = [getUserId(), this.state.playerList[this.state.playerTurn]["uid"]]
                let drawOps = {
                    playerList: this.state.playerList,
                    playerTurn: this.state.playerTurn,
                }

                EventManager.eventStruct = this.state.gameConfig["events"][eventName]
                //EventManager.initEvent(userIds, this.state.playerList, drawOps, this.state.objects[this.state.objects.length - 1], this.props.gid)
                EventManager.initEvent(
                    userIds, 
                    this.state.playerList, drawOps, 
                    this.state.objects[this.state.objects.length - 1], 
                    this.props.gid, this.actionEventCard, 
                    {jamtitle: this.props.roomName, jamlink: this.props.gid}
                )
            }
            // //New playerList with score
            // let updatedPlayerScore = setScore(this.state.loaded, updatedPlayerStep, getUserId(), this.state.gameConfig[this.state.yamlPhase.currentPhase]["scoreChange"])
            // updatedPermission = returnPermissions(getUserId(), updatedPlayerScore)
        }

        //Library Objects

        let tempLibMap = this.state.libraryObjects;
        var libID;

        if(obj.curlibID){
            libID = obj.curlibID

        }else{
            libID = Math.floor(Math.random() * Date.now()).toString()
        }

        obj.libID = libID
        tempLibMap.set(libID, obj)

        const last = this.state.phase.length - 1;
        const temp = [...this.state.phase]
        temp[last].object.push(obj)
        // console.log(obj)

        this.setState(prev =>({
            message: keyActionObj?.message ?? "",
            libraryObjects: tempLibMap,
            currentTurnStruct: keyActionObj?.newCurrentTurnStruct ?? prev.currentTurnStruct,
            currentPhase: keyActionObj?.newCurrentPhase ?? prev.currentPhase,
            playerList: keyActionObj?.updatedPlayerList ?? prev.playerList,
            permissionsConfig: keyActionObj?.updatedPermission ?? prev.permissionsConfig,
            playerTurn: keyActionObj?.newPlayerTurn ?? prev.playerTurn,
            objects: [...prev.objects, obj],
            latestObject: obj,
            phase: temp,
            endOfPhase: keyActionObj?.endOfPhase,
            latestID: (parseInt(this.state.latestID) + 1).toString(),
            draggedObject: null
        }),()=>{
            const {general,objects,latestObject, playerTurn, phase, turn, latestID, libraryObjects} = this.state
            CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
            CanvasUtil.mutateTPPData(this.state.currentPhase, this.state.currentTurnStruct, this.state.playerList, this.props.gid, this.props.status)
            CanvasState.latestObject = this.state.latestObject

            //Emitted to gameCanvas
            socket.emit('Game Add Object w/ Playerlist', {
                obj,
                latestID: this.state.latestID,
                message: this.state.message,
                event: this.state.event,
                currentTurnStruct: this.state.currentTurnStruct,
                currentPhase: this.state.currentPhase,
                newPlayerTurn: this.state.playerTurn,
                playerList: this.state.playerList,
                endOfPhase: this.state.endOfPhase
            });

            if(this.props.setup){
                CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))
            }else{
                socket.emit("Sync Library", {libraryObjects: Object.fromEntries(libraryObjects)})
                CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))///// if one player will have bug
            }
        })
    }

    /**
     * Handler to add multiple objects into the canvas
     * @param {[object]} objList list of objects to be added into the canvas
     * @param {string} filteredId default value is null; To be filtered out from the canvas if any
     * (Example: popping a stack card that will revert to normal cards)
     */
    addMultiObject = (objList, filteredId = null) =>{
        this.setState(state =>{
            const libraryObjects = state.libraryObjects
            // const listOfMultiSelect = state.listOfMultiSelect

            for(let i = 0; i <objList.length; i++){
                let obj = objList[i]
                let libID = null;

                if(obj.curlibID){
                    libID = obj.curlibID

                }else{
                    libID = Math.floor(Math.random() * Date.now()).toString()
                }

                obj.libID = libID
                libraryObjects.set(libID, obj)
                // listOfMultiSelect.push(obj.objId)
            }

            let objects = state.objects.filter((object) => {return object.objId !== filteredId})

            objects = [...objects, ...objList]

            const latestID = (Number(state.latestID) + objList.length).toString()
            return{
                objects,
                latestID,
                libraryObjects,
                // listOfMultiSelect
            }
        }, () =>{
            const {general,objects,latestObject, playerTurn, phase, turn, latestID, libraryObjects} = this.state

            socket.emit("Sync Library", {libraryObjects: Object.fromEntries(libraryObjects)})
            socket.emit("Game Add Multi Object", {objects: this.state.objects, latestID: this.state.latestID})

            CanvasUtil.mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, this.props.gid)
            CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))
        })
    }

    submit = (clientX, clientY) =>{

        let uid = getUserId()
        let player = this.state.playerList.find(player => player.uid === uid)
        let cardAddition;

        if(this.isSpectate){
            cardAddition = this.state.permissionsConfig.cardAddition;  // for spectator
        }else{
            cardAddition = player?.["permissions"]?.["cardAddition"]
        }

        // console.log(cardAddition)

        let clientCoordinates = {
            clientX: clientX,
            clientY: clientY
        }
        let canvasData = {
            scale: this.state.scale,
            x: this.state.canvasX,
            y: this.state.canvasY,
            latestID: this.state.latestID
        }

        if(this.draggedObject){
            const obj = CanvasUtil.checkObjDrop(clientCoordinates, canvasData, cardAddition, this.draggedObject,this.state.spectateInfo)
            if(obj){
                this.addObject(obj)
                this.draggedObject = null
            }
            else{
                return
            }
        }
        else if(this.state.selectedIDs.length > 0){
            const {selectedIDs, libraryObjects} = this.state
            let listOfLibObjs = []

            for(let i = 0; i < selectedIDs.length; i++){
                let selectedID = selectedIDs[i]

                if(!libraryObjects.get(selectedID)?.objId){
                    listOfLibObjs.push(libraryObjects.get(selectedID))
                }

            }
            const objs = CanvasUtil.checkMultiObjDrop(clientCoordinates, canvasData, cardAddition, listOfLibObjs)

            if(objs.length > 0){
                this.addMultiObject(objs)
            }
            else{
                return
            }
        }
    }

    updateNextID = () => {
        let n = (parseInt(this.state.latestID) + 1).toString();

        this.setState({
            latestID: n,
            draggedObject: null
        },()=>{
            socket.emit("Game Update Latest Object ID",{objId: n});
        })
    }

    removeAllObjects = () =>{
        this.setState({
            objects: []
        }, () =>{
            const {objects} = this.state
            CanvasUtil.mutateGameObjects(objects, this.props.gid)
        })
    }

    rightPanel = (open) =>{
        this.setState({
            panelOpen: open
        })
    }

    voteClose = () => {
        EE.emit('Reset Action')
        EE.emit('Close Overlay')
        EE.emit("Interrupt State Update")
        this.setState({
            vote: null,
        })
    }

    addNewLibraryCard = () =>{
        this.setState(state =>{
            const libraryObjects = state.libraryObjects
            const libID = (Math.floor(Math.random() * Date.now())).toString()
            libraryObjects.set(libID, {
                category: "Uncategorized",
                label: [],
                content: "",
                libID: libID,
                type: "predefined",
                imgUrl: null,
            })

            return{
                libraryObjects
            }
        },()=>{
            const {libraryObjects} = this.state

            if(this.props.setup){
                CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))
            }else{
                socket.emit("Sync Library", {libraryObjects: Object.fromEntries(libraryObjects)})
                CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))
            }
        })
    }

    changeColor = (category, color) =>{
        this.setState(state =>{
            const categoryColorMap = state.categoryColorMap

            categoryColorMap.set(category, color)

            return{
                categoryColorMap
            }
        }, ()=>{
            const {categoryColorMap} = this.state
            socket.emit("Change Category Color", {categoryColorMap: Object.fromEntries(categoryColorMap)})
            CanvasUtil.mutateCategoryColor(this.props.gid, Object.fromEntries(categoryColorMap))
        })
    }

    changeCard = (libID, change) =>{
        const {type, data} = change
        let changes = null
        let containsObjId = false

        this.setState(state =>{
            const libraryObjects = state.libraryObjects
            const listOfLabels = state.listOfLabels
            const listOfCategories = state.listOfCategories;
            const objects = state.objects
            const selectedIDs = state.selectedIDs

            if(type === "Content" && isEmpty(selectedIDs)){
                selectedIDs.push(libID)
            }

            for(let i = 0; i < selectedIDs.length; i++){
                let selectedID = selectedIDs[i]
                let object = libraryObjects.get(selectedID)

                if(type === "Label"){
                    let labelArr = []
                    for(let i = 0; i < data.length; i++){
                        let labelText = data[i]["label"]

                        labelArr.push(labelText)

                        if(!listOfLabels.find((labelObj) =>{return labelObj.label === labelText})){
                            listOfLabels.push({label: labelText})
                        }
                    }

                    object.label = labelArr
                    changes = labelArr
                }
                else if(type === "Category"){
                    // let categoryArr = []
                    // for(let i = 0; i < data.length; i++){
                        let categoryText = data[0]?.["category"]

                        if(!listOfCategories.find((categoryObj) =>{return categoryObj.category === categoryText}) && categoryText != null){
                            listOfCategories.push({category: categoryText})
                        }

                    //     categoryArr.push(categoryText)
                    // }

                    object.category = categoryText
                    changes = categoryText
                }
                else if(type === "Content"){
                    object.content = data
                    changes = data
                }
                else if(type === "Card Image"){
                    object.imgUrl = data
                    changes = data
                }

                libraryObjects.set(selectedID, object)

                if(object?.objId){
                    containsObjId = true
                    for(let i = 0; i <objects.length; i++){
                        let canvasObj = objects[i]

                        if(object.objId === canvasObj?.objId){
                            let cardRef = CanvasState.objRefMethods.get(object.objId)
                            //Syncing the position between objects in game canvas and the cards
                            //The positions of the cards in objects don't seem to sync with position
                            //in draggableCards
                            object.pos.x = cardRef.state.x
                            object.pos.y = cardRef.state.y
                            objects[i] = object
                            cardRef.modifyCard({type: type, data: changes})
                            break
                        }
                    }
                }
            }

            return{
                objects,
                listOfLabels,
                listOfCategories,
                libraryObjects
            }
        }, ()=>{
            const {libraryObjects, objects, listOfLabels, listOfCategories} = this.state

            CanvasUtil.mutateCategoryAndLabelData(this.props.gid, listOfCategories, listOfLabels)

            if(containsObjId){
                if(this.props.setup){
                    CanvasUtil.mutateGameObjects(objects, this.props.gid)
                }else{
                    socket.emit("Sync Objects", {objects: objects})
                    CanvasUtil.mutateGameObjects(objects, this.props.gid)
                }
            }

            if(this.props.setup){
                CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))
            }else{
                socket.emit("Sync Library", {libraryObjects: Object.fromEntries(libraryObjects)})
                CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))
            }
        })
    }

    /**
     * Duplicate single card in Library
     *
     * @param {string} [cardId]
     */
    duplicateSingleLibraryCard = (cardId) => {
        this.setState((state) => {
            const { libraryObjects } = state;
            const result = new Map(libraryObjects);

            const newObject = _.clone(libraryObjects.get(cardId));

            if (newObject?.objId) {
                delete newObject.objId;
            }

            const id = Math.floor(Math.random() * Date.now())?.toString();
            newObject.libID = id;
            result.set(id, newObject);

            return { libraryObjects: result };
        }, () => {
            const { libraryObjects } = this.state;
            CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects));
        });
    };

    /**
     * Bulk duplicate cards in Library
     */
    duplicateLibrary = () => {
        this.setState(state => {
            const { libraryObjects } = state;
            const selectedIDs = state.selectedIDs;
            for (let i=0; i< selectedIDs.length; i++) {
                let selectedID = selectedIDs[i]
                let newObject = _.clone(libraryObjects.get(selectedID))

                newObject?.objId && delete newObject.objId

                let libID = Math.floor(Math.random() * Date.now())
                libID = libID.toString()
                newObject.libID = libID

                libraryObjects.set(libID, newObject)
            }

            return { libraryObjects };
        }, () => {
            const { libraryObjects } = this.state;
            CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects));
        });
    }

    //Delete card in library
    deleteLibrary = (objectID) =>{
        let tempLibMap = this.state.libraryObjects
        let object = this.state.libraryObjects.get(objectID)
        if(!object.objId){
            tempLibMap.delete(objectID)

            this.setState({
                libraryObjects: tempLibMap,
                selectedIDs: [],
                listOfMultiSelect: []
            }, () =>{
                const {libraryObjects} = this.state
                CanvasUtil.mutateLibraryObjects(this.props.gid, Object.fromEntries(libraryObjects))
            })
        }
    }

    //pin for right panel
    pinRightPanel = (canvasId, libId) =>{
        this.setState(state =>{
            let listPinIds = state.listPinIds

            if(!listPinIds.includes(libId)){
                listPinIds.push(libId)
            }
            else{
                const i = listPinIds.indexOf(libId)
                listPinIds.splice(i, 1)
            }

            return{
                listPinIds
            }
        }, () =>{
        })
    }

    //Update player score
    setScorePlayerList = (playerID, value) =>{
        let updatedPlayer = setScore(this.state.playerList, playerID, value)

        this.setState({
            playerList: updatedPlayer
        }, ()=>{
            socket.emit("Sync Player Score", {uID: playerID, score:value})
        });
    }

    // Move Back Object

    moveBack = (objId) => {
        let n = this.state.objects
        let index = n.findIndex((j)=>{
            return j.objId === objId
        })

        let temp = n[index]
        n.splice(index,1)
        n.push(temp)

        this.setState({
            objects: n
        },()=>{
            const {objects} = this.state
            socket.emit("Sync Objects", {objects: objects});
            CanvasUtil.mutateGameObjects(objects, this.props.gid)
        })

    }


    /***
     * onImgUpload is to handle img upload
     */
     onImgUpload = (event, objLibID) =>{

        if (event.target.files && event.target.files[0]) {
            // let imgTemp = {
            //     imgSrc : URL.createObjectURL(event.target.files[0]),
            //     imgUploaded: true
            // }
            // this.setState({
            //     imgData: imgTemp
            // })

            const file = event.target.files[0];
            const data = new FormData();
            data.append('image', file);
            const req = request.post(`${config.backend.uri}/api/upload`);

            req.on('progress',(event)=>{
                const percent = Math.floor(event.percent);
                if (percent >= 100) {
                  this.setState({ imageCompleted: 100 });
                } else {
                  this.setState({ imageCompleted: percent });
                }
            });

            req.send(data);
            req.end((err, res) => {

                this.changeCard(objLibID, {type: "Card Image", data: res.body.data.upload.url})

            });
        }
    }
    /***
     * onImgPaste is to handle paste event in textarea if img is pasted
     */
    onImgPaste = (event, objLibID) =>{
        // use event.originalEvent.clipboard for newer chrome versions
        // console.log(event.clipboardData)
        // console.log(event.clipboardData.getData('text'));

        var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
        // find pasted image among pasted items
        var blob = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
            blob = items[i].getAsFile();
            }
        }

        if(blob !== null){
            const data = new FormData();
            data.append('image', blob);

            const req = request.post(`${config.backend.uri}/api/upload`);

            req.on('progress',(event)=>{
                const percent = Math.floor(event.percent);
                if (percent >= 100) {
                  this.setState({ imageCompleted: 100 });
                } else {
                  this.setState({ imageCompleted: percent });
                }
            });

            req.send(data);
            req.end((err,res)=>{
                this.changeCard(objLibID, {type: "Card Image", data: res.body.data.upload.url})
            })
        }else{
            alert("No image detected!")
        }

        // load image if there is a pasted image & if textarea is null
        // if (blob !== null && !this.state.content) {
        //     var reader = new FileReader();
        //     let result = ""

        //     reader.onload = function(event) {
        //         result = event.target.result;
        //     };

        //     let imgTemp = {
        //         imgSrc : result,
        //         imgUploaded: true
        //     }

        //     this.setState({
        //         imgData: imgTemp
        //     })

        //     reader.readAsDataURL(blob);
        // }

    }
    /**
     * removeImg is to remove the image on card
     */
    removeImg = (objLibID) =>{
        this.changeCard(objLibID, {type: "Card Image", data: null})
    }

    // Dropzone
    onDrop = (files, objLibID) => {

        const file = files.shift();
        const data = new FormData();
        data.append('image', file);
        const req = request.post(`${config.backend.uri}/api/upload`);

        req.on('progress',(event)=>{
            const percent = Math.floor(event.percent);
            if (percent >= 100) {
              this.setState({ imageCompleted: 100 });
            } else {
              this.setState({ imageCompleted: percent });
            }
        });

        req.send(data);
        req.end((err, res) => {

            this.changeCard(objLibID, {type: "Card Image", data: res.body.data.upload.url})

        });

        // this.setState(state=>{
        //     let imgData = state.imgData
        //     files.map((file) =>
        //         imgData.imgSrc = URL.createObjectURL(file)
        //     )
        //     imgData.imgUploaded = true

        //     return imgData
        // })

        // this.setState({
        //     imgSrc:  URL.createObjectURL(files[0]),
        //     imgUploaded: true
        // })
    };

    setSubDragging = (cmd) => {
        this.setState({
            subDragging: cmd
        })
    }

    /** @param {string} [cardId] */
    setSelectedCard = (cardId) => {
        this.setState({ listOfMultiSelect: [cardId] });
    };

    // function to close spectateInfo modal

    closeSpectatePopUp = () => {
        this.setState({
            spectatePopUp: false
        })
    }

    closeOutput = () => {
        this.setState({
            output: false
        })
    }

    handleDeleteOutputCategory = (index) => {
        let n = this.state.outputCategories;
        n.splice(index, 1)

        this.setState({
            outputCategories: n,
        })
    }

    getSpectateID = () => {
        if(this.isSpectate){
            if(this.state.spectateInfo && !getUserId()){
                return this.state.spectateInfo.spectator_id
            }
            return getUserId()
        }else{
            return null
        }
    }

    getOutputObject = () => {
        
        const {outputCategories,objects} = this.state

        // return []

        let filterByCat = []
        let result = []
        let finalResult = []
        let log = []

        let objs = objects.filter((o)=>{
            if(o.type === "stack" || o.type === "deck"){
                return false
            }else{
                return true
            }
        })

        // for(var i = 0;i<outputCategories.length;i++){
        //     let n = objects.filter((o)=>{
        //         if(outputCategories[i].includes(o.category)){
        //             return true
        //         }
        //     })

        //     if(n.length>0){
        //         filterByCat.push(n)
        //     }
        // }

        //////// NEW MODEL //////////////
        // if(outputCategories.length > 0){
        //     let n = objects.filter((o)=>{
        //         if(outputCategories[0].includes(o.category)){
        //             return true
        //         }
        //     })
    
        //     let m = objects.filter((o)=>{
        //         if(!outputCategories[0].includes(o.category)){
        //             return true
        //         }
        //     })
        //     filterByCat[0] = n
        //     filterByCat[1] = m
        // }
        /////////////////////////////////
        ///////////////// NEW MODEL 2 ///////////
        let getPrimeObject = objs.filter((o)=>{
            // console.log(o)

            if(o.paired){
                let paired = o.paired
                if(paired.left?.type === "origin"){
                    return false
                }
                if(paired.right?.type === "origin"){
                    return false
                }
                if(paired.up?.type === "origin"){
                    return false
                }
                if(paired.down?.type === "origin"){
                    return false
                }
    
                return true
            }
        })

        //let getPrimeObject = []
        filterByCat[0] = getPrimeObject
        // console.log(filterByCat)
        // console.log(filterByCat[0])
        if(filterByCat.length>0){
            let leadingGroup = filterByCat[0]
            leadingGroup.map((parent)=>{
                let record = []
                let processedParents = []
                let np = this.getOutputChild(filterByCat,parent,0,record,processedParents)
                result.push(np)
                log.push(record)
            })

            let temp = JSON.parse(JSON.stringify(log))
            finalResult = JSON.parse(JSON.stringify(result))
            //console.log(finalResult)
            //console.log(temp[0])
            for(var i = 0;i<finalResult.length;i++){
                for(var j = 0;j<temp[i].length;j++){
                    if(temp[i][j].child.length>0){
                        temp[i][j].child = []
                    }
                }
                let arr =  JSON.parse(JSON.stringify(temp[i].slice(1)))
                finalResult[i].child = arr
                //console.log(arr) 
                //console.log(finalResult[i].child)
            }

           // console.log(finalResult);
        }

        return finalResult.sort((a,b)=>{return a.pos.x - b.pos.x });
    }

    getOutputChild = (filterByCat,parent, n, record,processedParents) => {
        // console.log(parent.objId,parent.paired)
        let node = {
            id: parent.objId,
            content: parent.content,
            child: [],
            pos:{
                x: parent.pos.x,
                y: parent.pos.y,
            },
            category: parent.category,
            categoryColor: this.state.categoryColorMap.get(parent.category)
        }

        record.push(node)

        if(parent.paired && filterByCat.length>0){
            Object.keys(parent.paired).map((direction)=>{
                // console.log(filterByCat,parent,direction,n)
                if(parent.paired[direction]){
                    if(parent.paired[direction].type === "target"){

                        // let child = filterByCat[n+1].find((p)=>{return p.objId === parent.paired[direction].to})
                        let child = this.state.objects.find((p)=>{return p.objId === parent.paired[direction].to})
                        //console.log(child,filterByCat)
                        //let ppcheck = processedParents.find((p)=>{return p === child.objId})
                        if(child && (!processedParents.find((p)=>{return p === child.objId}))){
                            processedParents.push(child.objId)
                            //if(this.state.outputCategories.includes(child.category)){ //search object base on category
                            if((child.category === parent.category) && filterByCat.length === 1){
                                filterByCat[0].splice(filterByCat[0].findIndex((o)=>{return o.objId === child.objId}),1)
                            }

                            if(Object.values(child).every(x=>!x) == false){
                                // if child include parent , deny 
                                node.child.push(this.getOutputChild(filterByCat,child, n+1, record,processedParents))
                            }else{
                                let nc = {
                                    id: child.objId,
                                    content: child.content,
                                    child: [],
                                    pos:{
                                        x: child.pos.x,
                                        y: child.pos.y,
                                    },
                                    category: child.category,
                                    categoryColor: this.state.categoryColorMap.get(child.category)
                                }
                                node.child.push(nc)     
                            }
                            //}
                        }

                    }
                }
            })
        }

        // if(parent.paired && filterByCat.length>0){
        //     Object.keys(parent.paired).map((direction)=>{
        //         // console.log(filterByCat,parent,direction,n)
        //         if(parent.paired[direction]){
        //             if(parent.paired[direction].type === "target"){

        //                 // let child = filterByCat[n+1].find((p)=>{return p.objId === parent.paired[direction].to})
        //                 let child = this.state.objects.find((p)=>{return p.objId === parent.paired[direction].to})
        //                 // console.log(child,filterByCat)

        //                 if(this.state.outputCategories.includes(child.category)){
        //                     if((child.category === parent.category) && filterByCat.length === 1){
        //                         filterByCat[0].splice(filterByCat[0].findIndex((o)=>{return o.objId === child.objId}),1)
        //                     }

        //                     if(Object.values(child).every(x=>!x) == false){
        //                         node.child.push(this.getOutputChild(filterByCat,child, n+1, result))
        //                     }else{
        //                         let nc = {
        //                             id: child.objId,
        //                             content: child.content,
        //                             child: [],
        //                             pos:{
        //                                 x: child.pos.x,
        //                                 y: child.pos.y,
        //                             },
        //                             category: child.category,
        //                             categoryColor: this.state.categoryColorMap.get(child.category)
        //                         }
        //                         node.child.push(nc)
        //                     }
        //                 }
        //             }
        //         }
        //     })
        // }

        return node;
    }

    downloadOutput = (output) => {
        let yml = yaml.dump(output)
        var file = new Blob([yml],{type: 'text/yaml;charset=utf-8'})
        //saveAs(file,"jam_output")

        const filename = "output.yaml"
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    changeAEC = (card) => {
        this.actionEventCard = card
    }

    checkUnreceivedEvent = () => {
        // console.log(this.state.participants)
        let uid = getUserId()
        let {gid} = this.props
        this.state.participants.map((p)=>{
            if(p.uid === uid){
                if(!isEmpty(p.unreceivedEvents)){
                    /// process
                    p.unreceivedEvents.map((event)=>{
                        if(event.type === "notification"){
                            console.log(event.details.notification)
                            this.snackbar("Jambuilder Message",event.details.notification)
                        }

                        if(event.type === "draw"){
                            console.log(event)
                            EE.emit("Game Unreceived Draw Action",{event: event.details.event, penalizeId: event.details.penalizeId})
                        }

                        if(event.type === "drawALL"){
                            console.log(event)
                            EE.emit("Game Unreceived Draw All",event.details.hand)
                        }
                    })

                    CanvasUtil.mutateClearUnreceived(gid,uid)
                }
            }
        })
    }

    updateNotificationLog = (uid,notification) => {
        let {participants} = this.state
        // console.log(notification,participants,uid)
        //console.log(participants)

        let index = participants.findIndex(p=>{
            if(p.uid === uid){
                return true
            }
        }
        )

       // console.log(index)
       if(participants[index].notificationLog){
        participants[index].notificationLog.push(notification)
       }
        

        this.setState({
            participants
        })
    }
}

const sanitizeHtmlConfig = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'ol', 'ul', 'li', 'strike', 'u'],
    allowedAttributes: { a: ['href'] }
};

function RenderOutput ({node}){ // Recursive function for render pairing cards
    if(node.child.length > 0){
       // console.log("in child")
        return(
            <li>
                <strong style={{color:node.categoryColor}}>{node.category}:</strong> {sanitizeHtml(node.content,sanitizeHtmlConfig)}
                <ul>
                    {node.child.map((c)=><RenderOutput node={c}/>)}
                </ul>  
            </li>
        )
    }else{
        return(
            <li>
                <strong style={{color:node.categoryColor}}>{node.category}:</strong> {sanitizeHtml(node.content,sanitizeHtmlConfig)}
            </li>
        )
    }
}

export default withApollo(withStyles(styles)(GameCanvas))
